import {
    CHANGE_RECALL_NUMBER,
    CHECK_RECONNECT_SESSION,
    CLEAR_RECALL_SESSION,
    GET_ERROR_USERS_WEB_RTC, GET_MUTE_CONSULTATION_ID,
    GET_MUTE_ID,
    GET_USERS_WEB_RTC,
    RECONNECT_SESSION,
    SET_ACTIVE_SESSION,
    SET_MONITORING_HUB_ERROR,
    SET_RECALL_NUMBER, SET_STATUS_HUB_ERROR,
    SUCCESS_SESSION_RECONNECTED
} from "../types/types";
import {CHANGE_INTERNET_STATUS} from "../types/typesWebRTC";

const initialState = {
    searchResults: [],
    error: false,
    muteId: 0,
    muteConsultationId: 0,
    activeSession: true,
    reconnectSession: false,
    successReconnect: false,
    recallNumber: null,
    sessionId: null,
    recallNumberChanged: false,
    internetStatusError: false,
    checkSipConnection: false,
    monitoringHubError: false,
    statusHubError: false
};

export const WebRTCReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_USERS_WEB_RTC:
            return { ...state, searchResults: action.payload };
        case GET_ERROR_USERS_WEB_RTC:
            return { ...state, error: action.payload };
        case GET_MUTE_ID:
            return { ...state, muteId: action.payload };
        case GET_MUTE_CONSULTATION_ID:
            return { ...state, muteConsultationId: action.payload };
        case SET_ACTIVE_SESSION:
            return { ...state, activeSession: action.payload };
        case RECONNECT_SESSION:
            return { ...state, reconnectSession: action.payload };
        case SUCCESS_SESSION_RECONNECTED:
            return { ...state, successReconnect: action.payload };
        case SET_RECALL_NUMBER:
            return { ...state, recallNumber: action.payload.nameTo, sessionId: action.payload.sessionId };
        case CLEAR_RECALL_SESSION:
            return { ...state, sessionId: null};
        case CHANGE_RECALL_NUMBER:
            return { ...state, recallNumberChanged: action.payload };
        case CHANGE_INTERNET_STATUS:
            return { ...state, internetStatusError: action.payload };
        case CHECK_RECONNECT_SESSION:
            return { ...state, checkSipConnection: action.payload };
        case SET_MONITORING_HUB_ERROR:
            return { ...state, monitoringHubError: action.payload };
        case SET_STATUS_HUB_ERROR:
            return { ...state, statusHubError: action.payload };
        default:
            return state;
    }
};
