import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {ProgressBar} from "primereact/progressbar";
import {classNames} from "primereact/utils";
import {setDialogVisible, setEditType, setErrorFields, setSelectedCompanyIndex} from "../../../../redux/actions/actions";
import {getCampaignContactsWithPagination, getNotification, updateStateCampaign} from "../../../../redux/api/api";
import {Button} from "primereact/button";
import {getItem} from "../../../../redux/cache/cacheService";
import {codes} from "../../../../redux/notificationCodes";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Divider} from "primereact/divider";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";

const CompaniesListDetails = (props: any) => {
    const dispatch = useAppDispatch();
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'Dialer');
    // @ts-ignore
    const loader = useAppSelector(state => state.CompaniesListReducer.detailsLoading);
    // @ts-ignore
    const companiesData = useAppSelector(state => state.CompaniesListReducer.companiesData)
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.CompaniesListReducer.archiveTableToggle);
    // @ts-ignore
    const campaignContactsPageSize = useAppSelector(state => state.CompaniesListReducer.campaignContactsPageSize);
    const user = useAppSelector(state => state.UsersReducer.userData);
    let statusId = companiesData?.campaign?.statusId;
    const statusDone = companiesData?.campaign?.statusId === 6;
    // @ts-ignore
    const companiesDirectoryCampaignType = useAppSelector(state => state.CompaniesListReducer.companiesDirectoryCampaignType)
    const companies = useAppSelector(state => state.CompaniesListReducer)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [cancelNotificationStatusChange, setCancelNotificationStatusChange] = useState<boolean>(false);
    const [launchNotificationStatusChange, setLaunchNotificationStatusChange] = useState<boolean>(false);
    const [suspendNotificationStatusChange, setSuspendNotificationStatusChange] = useState<boolean>(false);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [selectedPhone, setSelectedPhone] = useState<any>(null);
    // @ts-ignore
    const companiesDirectoryTrunksPhones = useAppSelector(state => state.CompaniesListReducer.companiesDirectoryTranksPhones);
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);
    // @ts-ignore
    const trunkId = useAppSelector(state => state.CompaniesListReducer.companiesData.trunkId);
    const {
        update: hasDialerUpdatePermission,
        delete: hasDialerDeletePermission,
    } = dialerPermissions;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let cancelNotification = getItem(codes.cancel);
    let launchNotification = getItem(codes.launch);
    let suspendNotification = getItem(codes.suspend);

    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;

    // @ts-ignore
    let launchMessage = <div>{companies && companies.notificationLaunch ? companies.notificationLaunch.text : launchNotification ? launchNotification : progressBar}</div>
    // @ts-ignore
    let cancelMessage = <div>{companies && companies.notificationCancel ? companies.notificationCancel.text : cancelNotification ? cancelNotification : progressBar}</div>
    // @ts-ignore
    let suspendMessage = <div>{companies && companies.notificationSuspend ? companies.notificationSuspend.text : suspendNotification ? suspendNotification : progressBar}</div>

    const statusesDict = [
        {name: 'DRAFT', code: 1},
        {name: 'IDLE', code: 2},
        {name: 'ACTIVE', code: 3},
        {name: 'PAUSE', code: 4},
        {name: 'CANCEL', code: 5},
        {name: 'DONE', code: 6},
    ];

    const getNotificationMessage = (type: number) => {
        switch (type) {
            case 1:
                if (!cancelNotification && user.userId) {
                    dispatch(getNotification(user.userId, 107, user));
                }
                setCancelNotificationStatusChange(true);
                break;
            case 2:
                if (!launchNotification && user.userId) {
                    dispatch(getNotification(user.userId, 105, user));
                }
                setLaunchNotificationStatusChange(true);
                break;
            case 3:
                if (!suspendNotification && user.userId) {
                    dispatch(getNotification(user.userId, 106, user));
                }
                setSuspendNotificationStatusChange(true);
                break;
            default:
        }
    }

    const formatDate = (fullDate: string) => {
        const date = new Date(fullDate);

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        return `${formattedDay} / ${formattedMonth} / ${year}`;
    }

    const formatTime = (fullDate: string) => {
        const date = new Date(fullDate);

        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    const campaignTypeBodyTemplate = () => {
        return companiesDirectoryCampaignType.map((item: any) => {
            if (item.dictionaryId === companiesData.campaign?.campaignTypeId) {
                return item.name
            }
        });
    };

    const operatorsBodyTemplate = () => {
        const activeOperators = companiesData.campaign?.activeOperators;
        const countUsers = companiesData.campaign?.countUsers;

        if (activeOperators !== undefined && countUsers !== undefined) {
            return `${activeOperators} / ${countUsers}`;
        }
    }

    const countContactsBodyTemplate = () => {
        const calledContactCount = companiesData.campaign?.calledContactCount;
        const countContacts = companiesData.campaign?.countContacts;

        if (calledContactCount !== undefined && countContacts !== undefined) {
            return `${calledContactCount} / ${countContacts}`;
        }
    }

    const statusBodyTemplate = () => {
        let statusName = statusesDict.map((item) => {
            if (item.code === companiesData.campaign?.statusId) {
                return item.name
            }
        })
        return <span className={`customer-badge status-${companiesData.campaign?.statusId}`}>{statusName}</span>;
    };

    const branchBodyTemplate = () => {
        let element = branches?.find((element: { id: number; }) => element?.id === companiesData.campaign?.branchId);

        return (
            <div className="mt-1">
                <span style={{verticalAlign: 'middle'}} className="image-text">
                    {element?.id} {element?.name}
                </span>
            </div>
        );
    };

    let callStartTime = companiesData.scheduler?.startTime ? formatTime(companiesData.scheduler?.startTime) : '- - -';
    let callEndTime = companiesData.scheduler?.endTime ? formatTime(companiesData.scheduler?.endTime) : '- - -';
    const fields = [
        {label: "ID", value: companiesData.campaign?.campaignId},
        {label: "Branch", value: companiesData.campaign?.branchId ? branchBodyTemplate() : '- - -'},
        {label: "Тип", value: companiesData.campaign?.campaignId ? campaignTypeBodyTemplate() : '- - -'},
        {label: "А номер", value: companiesData?.campaign?.isGeneralTrunk ? 'Транк Кампанії' : 'Транк Оператора'},
        {label: "Напрямок дзвінків:", value: companiesData.campaign.firstCallOperator ? 'На оператора' : 'На клієнта'},
        {label: "Умови запуску", value: companiesData.scheduler?.typeEventStart ? 'В період' : '- - -'},
        {label: "Дата запуску", value: companiesData.scheduler?.startDate ? formatDate(companiesData.scheduler?.startDate) : '- - -'},
        {label: "Дата завершення:", value: companiesData.scheduler?.endDate ? formatDate(companiesData.scheduler?.endDate) : '- - -'},
        {label: "Розклад дзвінків:", value: companiesData?.scheduler ? `з-: ${callStartTime}  по- ${callEndTime}` : '- - -'},
        {label: "Оператори:", value: companiesData.campaign?.campaignId ? operatorsBodyTemplate() : '- - -'},
        {label: "Опрацьовано контактів:", value: companiesData.campaign?.campaignId ? countContactsBodyTemplate() : '- - -'},
        {label: "Успішно:", value: companiesData.campaign?.isDoneContactCount ? companiesData.campaign?.isDoneContactCount : '0'},
    ];

    const campaignDataSort = {
        pageNumber: campaignContactsPageSize.page,
        pageSize: campaignContactsPageSize.size,
        sort: {
            orderBy: "ContactName",
            "isDesc": false
        },
        campaignId: null
    }

    const openEditor = () => {
        dispatch(setSelectedCompanyIndex(companiesData.campaign?.campaignId || props.selectedRowData));
        dispatch(setDialogVisible(true));
        dispatch(setErrorFields(null));
        // dispatch(getCampaignId(props.selectedRowData, jwtToken?.jwtToken));
        campaignDataSort.campaignId = props.selectedRowData;
        dispatch(getCampaignContactsWithPagination(campaignDataSort, jwtToken?.jwtToken));
        if (hasDialerUpdatePermission) {
            dispatch(setEditType(true));
        } else {
            dispatch(setEditType(false));
        }
    }

    const StateCampaign = {
        "campaignId": companiesData?.campaign?.campaignId,
        "statusId": null,
        "userId": user?.userId
    }

    const updateCampaign = (campaignData: any, statusId: number) => {
        campaignData.statusId = statusId;
        dispatch(updateStateCampaign(campaignData, jwtToken?.jwtToken))
        if (statusId === 5) {
            dispatch(setDialogVisible(false));
        }
    };

    function getProgressBarColor(percent: number) {
        return percent <= 10 ? '#98ceec' :
            percent <= 20 ? '#84c1e7' :
                percent <= 30 ? '#72b5e3' :
                    percent <= 40 ? '#64abdf' :
                        percent <= 50 ? '#529fdb' :
                            percent <= 60 ? '#4295d7' :
                                percent <= 70 ? '#348cd3' :
                                    percent <= 80 ? '#2783d0' :
                                        percent <= 90 ? '#1678cc' :
                                            percent <= 100 ? '#026bc7' : '#026bc7';
    }

    function calculatePercentCount(percent: number) {
        return isNaN(percent) ? 0 : Math.round(percent);
    }

    const isDoneContactCountPercent = () => {
        const percent = calculatePercentCount(companiesData?.campaign?.calledContactCount * 100 / companiesData?.campaign?.countContacts);
        const progressBarColor = getProgressBarColor(percent);

        return (
            <div className="flex justify-content-center align-items-center justify-content-between w-full">
                <div className="mr-1">{percent}%</div>
                <ProgressBar color={progressBarColor} className='w-8 mr-2' showValue={false} value={percent}></ProgressBar>
            </div>
        )
    }

    const settingsBodyTemplate = () => {
        return <div className="flex item controls-buttons">
            {hasDialerDeletePermission && (<div className="flex flex-column justify-content-center px-0">
                <span className="text-field pt-0">Відмінити</span>
                <Button disabled={!archiveTableToggle || !companiesData.campaign.campaignId || statusDone} onClick={() => getNotificationMessage(1)} icon="pi pi-stop" className="p-button-text my-0 mx-auto"/>
            </div>)}
            {statusId !== 2 && statusId !== 3 && hasDialerUpdatePermission ? <div className="flex flex-column justify-content-center">
                <span className="text-field pt-0">Запустити</span>
                <Button disabled={!archiveTableToggle || !companiesData.campaign.campaignId || statusDone} onClick={() => getNotificationMessage(2)} icon="pi pi-play" className="p-button-text my-0 mx-auto"/>
            </div> : ''}
            {statusId === 2 || statusId === 3 && hasDialerUpdatePermission ? <div className="flex flex-column justify-content-center">
                <span className="text-field pt-0">Призупинити</span>
                <Button disabled={!archiveTableToggle || !companiesData.campaign.campaignId || statusDone} onClick={() => getNotificationMessage(3)} icon="pi pi-pause" className="p-button-text my-0 mx-auto"/>
            </div> : <></>}
        </div>
    }

    useEffect(() => {
        companiesDirectoryTrunksPhones?.map((item: any) => {
            if (trunkId === item.id) {
                setSelectedGateway(item.gatewayName);
                setSelectedPhone(item?.phoneNo);
                return item;
            }

            if (!trunkId) {
                setSelectedGateway(null);
                setSelectedPhone(null);
            }
        });
    }, [companiesDirectoryTrunksPhones, trunkId]);

    return (
        <div className="">
            <div className="relative">
                {loader && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
                <div className={classNames('grid', {'opacity-50': loader})} style={{paddingTop: "0.3rem", padding: '0.3rem 10px 5px'}}>
                    {windowWidth > 1248 && <div className="grid flex-wrap col-12 md:col-12 mb-0 flex">
                        <div className="field col-12 md:col-2 flex align-items-center m-0 p-0">
                            <div className="w-full mb-2">ID: {companiesData.campaign?.campaignId}</div>
                        </div>
                        <div className="field col-12 md:col-3 flex align-items-center m-0 p-0">
                            <div className="w-full">Оператори: {companiesData.campaign?.campaignId ? operatorsBodyTemplate() : '- - -'}</div>
                        </div>
                        <div className="field col-12 md:col-2 flex align-items-center m-0 p-0">
                            <div className="w-full">Тип: {companiesData.campaign?.campaignId ? campaignTypeBodyTemplate() : '- - -'}</div>
                        </div>
                        <div className="field col-12 md:col-3 flex align-items-center m-0 p-0">
                            <div className="w-full flex justify-content-between max-w-16rem"><span>Опрацьовано контактів:</span> {companiesData.campaign?.campaignId ? countContactsBodyTemplate() : '- - -'}</div>
                        </div>
                        <div className="field col-12 md:col-2 flex align-items-center justify-content-end m-0 p-0">
                            <div className="field col-12 md:col-6 flex align-items-center justify-content-end m-0 p-0 mr-2">
                                {statusBodyTemplate()}
                            </div>
                            <div className="field col-12 md:col-6 flex align-items-center m-0 p-0">
                                <button className="close-button block cursor-pointer mr-1" onClick={() => {
                                    openEditor();
                                }}><span className={classNames('', {
                                    'pi pi-pencil': archiveTableToggle && hasDialerUpdatePermission,
                                    'pi pi-eye': !archiveTableToggle || !hasDialerUpdatePermission
                                })}></span></button>
                                <button className="close-button block cursor-pointer ml-0" onClick={() => props.setSelectedRowData(null)}><span className="pi pi-times"></span></button>
                            </div>
                        </div>
                        <div className="field col-12 md:col-2 flex align-items-center m-0 p-0">
                            <p className="w-full font-bold">{companiesData.campaign?.campaignName}</p>
                        </div>
                        <div className="field col-12 md:col-3 flex align-items-center m-0 p-0">
                            <div className="w-full">Стратегія операторів: {companiesData.campaign?.campaignId ? operatorsBodyTemplate() : '- - -'}</div>
                        </div>
                        <div className="field col-12 md:col-2 flex align-items-center m-0 p-0">
                            <p className="w-full">Напрямок: {companiesData.campaign.firstCallOperator ? 'На оператора' : 'На клієнта'}</p>
                        </div>
                        <div className="field col-12 md:col-3 flex align-items-center m-0 p-0">
                            <div className="w-full max-w-16rem">{isDoneContactCountPercent()}</div>
                        </div>
                    </div>}

                    {windowWidth > 1248 && <Divider className="m-0"/>}
                    {windowWidth > 1248 &&
                        <React.Fragment>
                            <div className="field col-12 md:col-12 flex align-items-center m-0 px-0 pb-2">

                                <div className="field col-12 md:col-3 flex align-items-center m-0 p-0">
                                    <p className="w-full">{companiesData?.campaign?.isGeneralTrunk ? 'Транк Кампанії' : 'Транк Оператора'}</p>
                                </div>
                                <div className="field col-12 md:col-2 flex align-items-center m-0 p-0">
                                    <p className="w-full">Бранч</p>
                                </div>
                                <div className="field col-12 md:col-7 flex align-items-center m-0 p-0">
                                    <p className="w-full pl-2">Розклад роботи</p>
                                </div>
                            </div>
                            <Divider className="mb-3 mt-0"/>
                            <div className="field col-12 md:col-12 flex m-0 scroll-detail-items p-0">
                                <div className="field col-12 md:col-3 flex m-0 p-0">
                                    {companiesData?.campaign?.isGeneralTrunk && <div className="field col-12 md:col-12 flex m-0 p-0 pr-2 align-self-start">
                                        <InputText id="type"
                                                   value={selectedGateway || ''}
                                                   disabled
                                                   placeholder="Транк"
                                                   className="opacity-100 w-full mr-2 bg-transparent pl-0"
                                        />
                                        <InputText
                                            value={selectedPhone || ''}
                                            placeholder="Номер"
                                            disabled
                                            className="opacity-100 w-full bg-transparent pl-0"
                                        />
                                    </div>}
                                </div>
                                <div className="field col-12 md:col-2 flex m-0 p-0 flex-wrap">
                                    {companiesData.campaign?.branchId ? branchBodyTemplate() : '- - -'}
                                </div>
                                <div className="field col-12 md:col-7 flex m-0 p-0">
                                    <DataTable className="col-12 md:col-12 pl-0 py-0 pr-0" value={[companiesData]}
                                    >
                                        <Column
                                            header={'Умови запуску'}
                                            className="justify-content-start align-content-start"
                                            body={(data) => data.scheduler?.typeEventStart ? 'В період' : '- - -'}
                                            style={{cursor: 'pointer'}}
                                        />
                                        <Column
                                            header={'Дата запуску'}
                                            className="justify-content-start align-content-start"
                                            body={(data) => data.scheduler?.startDate ? formatDate(companiesData.scheduler?.startDate) : '- - -'}
                                            style={{cursor: 'pointer'}}
                                        />
                                        <Column
                                            header={'Дата завершення'}
                                            className="justify-content-start align-content-start"
                                            body={(data) => data.scheduler?.endDate ? formatDate(companiesData.scheduler?.endDate) : '- - -'}
                                            style={{cursor: 'pointer'}}
                                        />
                                        <Column
                                            header={'Розклад дзвінків'}
                                            className="justify-content-start align-content-start"
                                            body={(data) => data?.scheduler ?  <>
                                                <span>з- {callStartTime}</span> <span>по- {callEndTime}</span>
                                            </> : '- - -'}
                                            style={{cursor: 'pointer'}}
                                        />
                                        <Column
                                            header={'Управління кампанією'}
                                            className="justify-content-start pt-2"
                                            body={settingsBodyTemplate}
                                            style={{cursor: 'pointer'}}
                                        />
                                    </DataTable>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    {windowWidth <= 1248 && <div className="grid col-12 md:col-12 mb-0 px-0">
                        <div className="field col-12 md:col-12 flex align-items-center m-0">
                            <p className="w-full">{companiesData.campaign?.campaignName}</p>
                        </div>
                        {(<div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>)}
                    </div>}
                    {windowWidth <= 1248 && <div className="field col-6 md:col-6 flex align-items-center m-0">
                        {isDoneContactCountPercent()}
                    </div>}
                    {windowWidth <= 1248 && <div className="field col-6 md:col-6 flex align-items-center m-0 justify-content-end">
                        {statusBodyTemplate()}
                    </div>}
                    {windowWidth <= 1248 && fields.map((field: any) => (
                        <React.Fragment key={field.label}>
                            <div className="field col-12 md:col-6 flex align-items-center m-0">
                                <div>{field.label}</div>
                            </div>
                            <div className="field col-12 md:col-6 flex align-items-center m-0">
                                <div>{field.value}</div>
                            </div>
                        </React.Fragment>
                    ))}
                    {windowWidth <= 1248 && <div className="flex w-full">
                        <div className="field col-12 md:col-12">
                            <div className="flex item controls-buttons justify-content-center">
                                {hasDialerDeletePermission && (<div className="flex flex-column justify-content-center pt-0">
                                    <span className="text-field pt-0">Відмінити</span>
                                    <Button disabled={!archiveTableToggle || !companiesData.campaign.campaignId || statusDone} onClick={() => getNotificationMessage(1)} icon="pi pi-stop" className="p-button-text my-0 mx-auto"/>
                                </div>)}
                                {statusId !== 2 && statusId !== 3 && hasDialerUpdatePermission ? <div className="flex flex-column justify-content-center">
                                    <span className="text-field pt-0">Запустити</span>
                                    <Button disabled={!archiveTableToggle || !companiesData.campaign.campaignId || statusDone} onClick={() => getNotificationMessage(2)} icon="pi pi-play" className="p-button-text my-0 mx-auto"/>
                                </div> : ''}
                                {statusId === 2 || statusId === 3 && hasDialerUpdatePermission ? <div className="flex flex-column justify-content-center">
                                    <span className="text-field pt-0">Призупинити</span>
                                    <Button disabled={!archiveTableToggle || !companiesData.campaign.campaignId || statusDone} onClick={() => getNotificationMessage(3)} icon="pi pi-pause" className="p-button-text my-0 mx-auto"/>
                                </div> : <></>}
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <ConfirmDialog visible={cancelNotificationStatusChange} onHide={() => setCancelNotificationStatusChange(false)} message={cancelMessage}
                           header="Попередження:" icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => updateCampaign(StateCampaign, 5)}/>
            <ConfirmDialog visible={launchNotificationStatusChange} onHide={() => setLaunchNotificationStatusChange(false)} message={launchMessage}
                           header="Попередження:" icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => updateCampaign(StateCampaign, 2)}/>
            <ConfirmDialog visible={suspendNotificationStatusChange} onHide={() => setSuspendNotificationStatusChange(false)} message={suspendMessage}
                           header="Попередження:" icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => updateCampaign(StateCampaign, 4)}/>
        </div>
    )
}

export default CompaniesListDetails
