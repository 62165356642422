import {InputText} from 'primereact/inputtext';
import {Dropdown} from "primereact/dropdown";
import {classNames} from "primereact/utils";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {MultiSelect} from "primereact/multiselect";
import React, {useEffect, useState} from "react";
import {getBranchesOwner} from "../../../redux/api/apiBranchList";
import {setBranchField, toggleUserLider} from "../../../redux/actions/actionsBranchList";
import {Checkbox} from "primereact/checkbox";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {Button} from "primereact/button";
import {getUserDetails} from "../../../redux/api/apiUsersList";
import {setEditType} from "../../../redux/actions/actions";
import UserListDialog from "../../Settings/UsersList/UserDialod";

const BranchDialogGeneralSettings = () => {
    const dispatch = useAppDispatch();
    const branchesOwners = useAppSelector(state => state.BranchesListReducer.branchesOwners);
    const branchData = useAppSelector(state => state.BranchesListReducer.branchData);
    const branchesList = useAppSelector(state => state.BranchesListReducer.branchesList);
    const errorFields = useAppSelector(state => state.BranchesListReducer.errorFields);
    const [parentId, setParentId] = useState(null);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const branchPermissions = checkPermissions(rolesVision, 'Branch');
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const usersManagerPermissions = checkPermissions(rolesVision, 'UsersManager');
    // @ts-ignore
    const detailsLoading = useAppSelector(state => state.UsersListReducer.detailLoading);
    const [currentUserId, setCurrentUserId] = useState<any>(null);
    const {
        update: hasUsersManagerUpdatePermission,
        visible: hasUsersManagerVisiblePermission
    } = usersManagerPermissions;
    const {
        // create: hasBranchCreatePermission,
        // update: hasBranchUpdatePermission,
    } = branchPermissions;

    useEffect(() => {
        dispatch(getBranchesOwner(jwtToken?.jwtToken, branchData?.parentId ? branchData?.parentId : 0));
    }, [branchData?.parentId]);

    interface IDataItem {
        id: number;
        name: string;
    }

    interface ITreeNode {
        data: IDataItem;
        children: ITreeNode[];
    }

    function flattenTree(tree: ITreeNode[]): IDataItem[] {
        const result: IDataItem[] = [];

        function traverse(node: ITreeNode) {
            result.push(node.data);
            if (node.children) { // Check if 'children' exists
                node.children.forEach(child => traverse(child));
            }
        }

        tree.forEach(node => traverse(node));

        return result;
    }

    const formattedData: IDataItem[] = flattenTree(branchesList);
    const filteredList = formattedData?.filter(item => item.id !== branchData?.id) || [];

    useEffect(() => {
        setParentId(branchData?.parentId);
    }, [branchData?.parentId]);

    const updatedUsersData = branchesOwners?.users?.map((item: { [x: string]: any; branchId: any; branchName: any; id: any; ownerName: any; roles: any; }) => {
        const {
            branchId,
            branchName,
            id,
            ownerName,
            isEdit,
            roles,
            isLider,
            // isActive,
            ...rest
        } = item;
        const fullName = `${item?.lastName} ${item?.firstName}`;
        // const fullNameWithBranchName = `${item?.lastName} ${item?.firstName}  -  ${item?.branchName ? item?.branchName : ''}`;
        return {...rest, fullName};
    });

    const isUsersIdPresent = branchData?.users?.some((skillGroup: { userId: any; }) =>
        updatedUsersData?.some((levelGroup: { userId: any; }) => levelGroup.userId === skillGroup.userId)
    );

    const updatedOwnersData = branchesOwners?.ownerUsers?.map((item: { [x: string]: any; branchId: any; branchName: any; id: any; ownerName: any; roles: any; }) => {
        const {
            branchId,
            branchName,
            id,
            ownerName,
            roles,
            ...rest
        } = item;
        const fullName = `${item?.lastName} ${item?.firstName}`;
        return {...rest, fullName};
    });

    const branchDataUsers = branchData?.users?.map((item: { [x: string]: any; lastName: any; firstName: any; middleName: any; }) => {
        const {middleName, ...rest} = item;
        const fullName = `${item?.lastName} ${item?.firstName}`;
        const isLider = item?.isLider ? item?.isLider : false;
        return {...rest, fullName, isLider};
    });

    const branchDataUsersWithoutLiderField = branchData?.users?.map((item:any) => {
        const {middleName, isLider, isActive, ...rest} = item;
        const fullName = `${item?.lastName} ${item?.firstName}`;
        return {...rest, fullName};
    });

    const filteredUsers = branchData?.users?.filter((skillGroup: { userId: any; }) =>
        updatedUsersData?.some((levelGroup: { userId: any; }) => levelGroup.userId === skillGroup.userId)
    ) || [];

    function findUserByFullName(fullNameToFind: any) {
        return updatedOwnersData?.find((user: any) => user.fullName === fullNameToFind);
    }

    let ownerName = null;
    if (typeof branchData?.ownerName === 'object' && branchData?.ownerName !== null) {
        ownerName = branchData?.ownerName
    }
    else if (typeof branchData?.ownerName === 'string') {
        ownerName = findUserByFullName(branchData?.ownerName);
    }

    const handleCheckboxClick = (userId: string) => {
        dispatch(toggleUserLider(userId));
    };

    return (
        <div className="grid p-fluid">
            <div className="field col-12 md:col-4">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.parentName && !branchData?.parentName})}>Ім'я батьківського бранчу *</label>
                <Dropdown
                    value={branchData?.parentId || ''}
                    onChange={(e) => {
                        dispatch(setBranchField({fieldName: 'parentId', value: e.target.value}))
                    }}
                    options={filteredList}
                    optionLabel="name"
                    optionValue="id"
                    filter
                    placeholder="Виберіть:"
                    className={classNames('w-full', {'p-invalid': errorFields?.parentId && !parentId})}
                    disabled={branchData?.isArchived || !editType}
                />
            </div>
            <div className="field col-12 md:col-4">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.name && !branchData?.name})}>Ім'я поточного бранча *</label>
                <InputText
                    id="name"
                    value={branchData?.name || ''}
                    maxLength={90}
                    className={classNames('', {'p-invalid': errorFields?.name && !branchData?.name})}
                    onChange={e => {
                        dispatch(setBranchField({fieldName: 'name', value: e.target.value}));
                    }}
                    disabled={branchData?.isArchived || !editType}
                />
            </div>
            <div className="field col-12 md:col-4">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.ownerName && !ownerName})}>Ім'я власника</label>
                <Dropdown
                    value={ownerName || ''}
                    onChange={(e) => {
                        dispatch(setBranchField({fieldName: 'ownerName', value: e.target.value}))
                        dispatch(setBranchField({fieldName: 'ownerId', value: e.target.value.userId}))
                    }}
                    options={updatedOwnersData}
                    optionLabel="fullName"
                    filter
                    placeholder="Виберіть власника"
                    className={classNames('w-full', {'p-invalid': errorFields?.ownerName && !ownerName})}
                    disabled={branchData?.isArchived || !editType}
                />
            </div>
            {!branchData?.isArchived && <div className="field col-12 md:col-4">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.users && !branchDataUsersWithoutLiderField})}>Додати користувачів</label>
                <MultiSelect
                    id="role"
                    value={branchDataUsersWithoutLiderField}
                    options={updatedUsersData}
                    placeholder="Вибрати користувачів *"
                    optionLabel="fullName"
                    selectedItemsLabel={isUsersIdPresent ? `Користувачив обрано: ${filteredUsers?.length}` : ""}
                    filter
                    onChange={e => {
                        dispatch(setBranchField({fieldName: 'users', value: e.target.value}));
                    }}
                    disabled={branchData?.isArchived || !editType}
                />
            </div>}
            {branchData?.isArchived && <div className="field col-12 md:col-4"></div>}
            <div className="field col-12 md:col-8"></div>
            <div className="field col-12 md:col-4">
                {branchData?.users && branchData?.users?.length && isUsersIdPresent ? <h6>Список користувачів :</h6> : ''}
                {branchData?.users && branchDataUsers?.map((user: any, index: number) => (
                    <React.Fragment key={index}>
                        <ul className="field col-12 md:col-8 flex align-items-center m-0 w-full">
                            <li className="w-full flex align-items-center justify-content-between">
                                <div className="flex align-items-center">
                                {hasUsersManagerVisiblePermission && <Button
                                    className="max-w-4rem p-button-secondary mr-2"
                                    style={{minWidth: '40px'}}
                                    loading={detailsLoading && currentUserId === user.userId}
                                    disabled={branchData?.isArchived || detailsLoading}
                                    icon={hasUsersManagerUpdatePermission ? 'pi pi-pencil' :  !hasUsersManagerUpdatePermission ? 'pi pi-eye' : ''}
                                    onClick={() => {
                                        setCurrentUserId(user.userId);
                                        dispatch(getUserDetails(user.userId, jwtToken?.jwtToken, true));
                                        if (hasUsersManagerUpdatePermission) {
                                            dispatch(setEditType(true));
                                        } else {
                                            dispatch(setEditType(false));
                                        }
                                    }}/>}
                                {user.fullName}
                                </div>
                                <div className="flex align-items-center"><p className="pr-2 m-0">Лідер</p>
                                    <Checkbox name="category"
                                              value={user.isLider}
                                              disabled={branchData?.isArchived || !editType}
                                              checked={user.isLider}
                                              onChange={() => handleCheckboxClick(user.userId)}/></div>
                            </li>
                        </ul>
                    </React.Fragment>
                ))}
            </div>
            <UserListDialog/>
        </div>
    );
};

export default BranchDialogGeneralSettings;
