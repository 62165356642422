import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {InputSwitch} from "primereact/inputswitch";
import {Button} from "primereact/button";
import {InputNumber} from "primereact/inputnumber";
import {setTrunkIds} from "../../../../redux/actions/actionsTrunk";
import {ConfirmDialog} from "primereact/confirmdialog";
import {deleteTrunkPhoneById, inactiveTrunkPhoneById} from "../../../../redux/api/apiTrunks";

const PhoneSettings = () => {
    const dispatch = useAppDispatch();
    const trunkData = useAppSelector(state => state.TrunksListReducer.trunkData);
    const errorFields = useAppSelector(state => state.TrunksListReducer.errorFields);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const [selectedPairs, setSelectedPairs] = useState<any>([{phoneNo: null, isActive: false}]);
    const archiveTableToggle = useAppSelector(state => state.TrunksListReducer.archiveTrunkTableToggle)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visible, setVisible] = useState<boolean>(false);
    const [visibleInActive, setVisibleInActive] = useState<boolean>(false);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [currentId, setCurrentId] = useState<any>(null);
    const [inactiveId, setInactiveId] = useState<any>(null);

    useEffect(() => {
        if (trunkData?.phones?.length) {
            setSelectedPairs(trunkData?.phones);
            dispatch(setTrunkIds(trunkData?.phones));
        }
    }, [trunkData]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const removePair = (indexToRemove: number) => {
        setSelectedPairs((currentPairs: any[]) =>
            currentPairs.filter((_, index) => index !== indexToRemove)
        );
    };

    const deletePair = () => {
        dispatch(deleteTrunkPhoneById(trunkData?.id, currentId, jwtToken?.jwtToken));
        setVisible(false);
    }

    const addPair = () => {
        setSelectedPairs((prevPairs: any[]) => {
            const newPhone = {
                "phoneNo": null,
                "isActive": false
            }
            return [...prevPairs, newPhone];
        });
    };

    useEffect(() => {
        dispatch(setTrunkIds(selectedPairs));
    }, [selectedPairs, visible, trunkData]);

    const changePhone = (value: any, index: number) => {
        setSelectedPairs((prevPairs: any[]) =>
            prevPairs.map((pair, idx) =>
                idx === index ? {...pair, phoneNo: `${value}`} : pair
            )
        );
    };

    const changeActive = (id: number) => {
        setVisibleInActive(true);
        setInactiveId(id);
    };

    const inActivePhone = () => {
        dispatch(inactiveTrunkPhoneById(inactiveId, trunkData?.id, jwtToken?.jwtToken));
    }

    const activeNumber = (value: any, index: number) => {
        setSelectedPairs((prevPairs: any[]) =>
            prevPairs.map((pair, idx) =>
                idx === index ? {...pair, isActive: value} : pair
            )
        );
    }

    return (
        <div className="grid p-fluid">
            <div className="field col-12 md:col-12 flex flex-wrap mb-0">
                <div className="field col-6 md:col-4 mb-0">
                    <label>Номер*</label>
                </div>
                <div className="field col-6 md:col-3 mb-0">
                    <label>Стан*</label>
                </div>
                <div className="field col-12 md:col-12 flex flex-wrap align-items-center">
                    {selectedPairs && selectedPairs?.map((phone: any, index: number) => {
                        return <div key={index} className="flex flex-wrap field col-12 p-0 m-0 align-items-center">
                            <div className="field col-6 md:col-4 p-0 m-0">
                                <InputNumber
                                    useGrouping={false}
                                    onChange={(e) => changePhone(e.value, index)}
                                    disabled={!archiveTableToggle || !editType}
                                    className="max-w-20rem" value={phone.phoneNo}/>
                            </div>
                            <div className="field col-6 md:col-3 flex align-items-center mb-0">
                                <InputSwitch
                                    disabled={!archiveTableToggle || !editType}
                                    className="mr-3 ml-1"
                                    checked={phone.isActive}
                                    onChange={(e) => {
                                        if (phone.isActive) {
                                            changeActive(phone.id);
                                        } else {
                                            activeNumber(e.target.value, index)
                                        }
                                    }}
                                />
                                {windowWidth > 1248 && <label className="mb-0" htmlFor="icon">{phone.isActive ? 'Активний' : 'Не активний'}</label>}
                                {<Button icon="pi pi-times" className="p-button-secondary ml-auto"
                                         disabled={!archiveTableToggle || !editType || selectedPairs.length < 1}
                                         onClick={() => {
                                             if (!phone.id) {
                                                 removePair(index)
                                             } else {
                                                 setCurrentId(phone?.id);
                                                 setVisible(true);
                                             }
                                         }}
                                />
                                }
                            </div>
                        </div>
                    })}
                    {errorFields?.phones && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span className="title p-error text-sm">{errorFields?.name}*</span></div>}
                    <Button disabled={!archiveTableToggle || !editType} icon="pi pi-plus" onClick={() => addPair()} className="max-w-12rem mt-4"><label className="pl-2">Додати номер</label></Button>
                </div>
            </div>
            <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={'Хочете видалити номер ?'}
                           icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити"
                           accept={() => deletePair()}
            />
            <ConfirmDialog visible={visibleInActive} onHide={() => setVisibleInActive(false)} message={'Хочете деактивувати номер ?'}
                           icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити"
                           accept={() => inActivePhone()}
            />
        </div>
    );
};

export default PhoneSettings;
