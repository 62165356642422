import axios from "axios";
import {API_URL, base64ToBlob} from "../config";
import {hideError, hideLoader, showExportLoader, showLoader} from "../actions/actionsMonitoringList";
import {CHANGE_CURRENT_USER_PASSWORD_SUCCESS, CHANGE_PASSWORD_LOADING, CHANGE_PASSWORD_SUCCESS, GET_HASH_LOADING, GET_NOTIFICATION_ERROR, SHOW_GET_COMPANIES_DIRECTORIES_TRANKS_PHONE_ERROR} from "../types/types";
import {
    GET_PHONES_DICTIONARY,
    GET_USER_ALL_ROLES,
    GET_USER_DETAILS,
    GET_USER_PAGINATION,
    GET_USER_ROLES, GET_USERS_DIRECTORIES_TRUNKS_PHONE,
    GET_USERS_LIST,
    HIDE_USER_DELETE_MESSAGE,
    HIDE_USER_SAVE_MESSAGE,
    SET_USER_DELETE_MESSAGE_SUCCESS,
    SET_USER_STATUS,
    SET_USER_STATUS_ERROR,
    SET_USER_STATUS_SUCCESS,
    SHOW_USER_CREATE_MESSAGE, TECH_USER_LOADING
} from "../types/typesUsersList";
import {
    getUserBranches,
    getUserCountries,
    getUserLanguages,
    getUserRoles,
    getUserStatusesById,
    getUserStatusesData,
    hideUserCreateMessage,
    hideUserSaveMessage,
    setCurrentPageSize,
    setUserError,
    setUserErrorFields, setUsersDialogVisible,
    showUserDeleteMessage,
    showUserDetailsLoading,
    showUserSaveMessage
} from "../actions/actionsUsersList";
import {googleAuthLoginError, hideErrorMessage} from "../actions/actions";
import {SHOW_DETAIL_LOADING} from "../types/typesMonitoringList";
import {showAccessDeniedNotifications} from "../actions/actionsNotifications";
import {GET_BRANCHES, GET_USER_BRANCHES_LIST} from "../types/typesBranchesList";
import {hideBranchLoader, setBranchErrorFields, showBranchLoader} from "../actions/actionsBranchList";
import {showRolesErrorMessage} from "../actions/actionsRoles";
import {GET_TECH_USER_DICTIONARY, SUCCESS_CREATED_TECH_USER} from "../types/typesRoles";
import {getBranchDetailById} from "./apiBranchList";

let dataSort = {
    isActive: true,
    pageNumber: 1,
    pageSize: 10,
    sort: {
        orderBy: "Id",
        isDesc: true
    }
}

export function getUsersListWithPaginationFilterAndSorting(data: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showLoader());
        dispatch(hideError());
        dispatch(setUserError(false));
        axios.post(`${API_URL}UsersManager/GetAllUsersWithPagination`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_USERS_LIST, payload: response.data.data.items})
                dispatch({type: GET_USER_PAGINATION, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideLoader())
            })
    }
}

export function getUserRolesData(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false))
        axios.get(`${API_URL}Role/GetAllRoles?isActive=true&isArchived=false`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch(getUserRoles(response.data.data))
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideLoader())
            })
    }
}

export function getUserBranchesData(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false))
        axios.get(`${API_URL}Branch/GetAllBranches?isArchived=false`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch(getUserBranches(response.data.data))
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideLoader())
            })
    }
}

export function getUsersManagerUserBranchesData(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false))
        axios.get(`${API_URL}UsersManager/GetAllBranches?isArchived=false`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch(getUserBranches(response.data.data))
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideLoader())
            })
    }
}

export function getUserLanguagesData(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false))
        axios.get(`${API_URL}Language/GetAllLanguages`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                dispatch(getUserLanguages(response.data.data))
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideLoader())
            })
    }
}

export function getUserCountriesData(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false))
        axios.get(`${API_URL}Language/GetAllCountries`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch(getUserCountries(response.data.data))
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideLoader())
            })
    }
}

export function getUserStatuses(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false))
        axios.get(`${API_URL}Status/GetUserStatuses`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch(getUserStatusesData(response.data.data))
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideLoader())
            })
    }
}

export function getUserStatusesForDetails(jwtToken: any, userId: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false))
        axios.get(`${API_URL}Status/GetUserStatuses?userId=${userId}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch(getUserStatusesById(response.data.data))
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideLoader())
            })
    }
}


export function getUserDetails(userId: any, jwtToken: any, remote?: boolean) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showUserDetailsLoading(true));
        dispatch(setUserError(false));
        dispatch(hideError());
        axios.get(`${API_URL}UsersManager/GetUserByUserId?userId=${userId}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: SHOW_DETAIL_LOADING, payload: false})
                let data = response.data.data;
                data.billingId = 1;
                data.userDateTimeUTC = "2023-07-10T08:52:10.538Z"
                dispatch({type: GET_USER_DETAILS, payload: response.data.data});
                if (remote) {
                    dispatch(setUsersDialogVisible(true));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(showUserDetailsLoading(false))
            })
    }
}

export function createUser(data: any, jwtToken: any, userListSort?: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideUserCreateMessage());
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch(setUserError(false))
        axios.post(`${API_URL}UsersManager/CreateUser`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_USER_DETAILS, payload: response.data.data})
                    dispatch(getUsersListWithPaginationFilterAndSorting(userListSort || dataSort, jwtToken));
                    dispatch({type: SHOW_USER_CREATE_MESSAGE})
                } else if (response.data.statusCode === 400) {
                    let errorMessage = {"userNameError": response.data.message};
                    dispatch(setUserErrorFields(errorMessage));
                } else if (response.data.statusCode === 403) {
                    let errorMessage = {"mobilePhone": {name: response.data.message}};
                    dispatch(setUserErrorFields(errorMessage));
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                } else if (response.data.statusCode === 409) {
                    let errorMessage = {"email": {email: response.data.message}};
                    dispatch(setUserErrorFields(errorMessage));
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                } else if (response.data.statusCode === 304) {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                } else {
                    dispatch(setUserErrorFields({'apiError': true, 'message': response.data.title, 'title': response.data.message + '*'}));
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch(hideUserCreateMessage());
            })
    }
}

export function updateUser(data: any, jwtToken: any, userListSort?: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideUserSaveMessage())
        dispatch(setUserError(false))
        dispatch({type: HIDE_USER_SAVE_MESSAGE})
        axios.put(`${API_URL}UsersManager/UpdateUser`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_USER_DETAILS, payload: response.data.data})
                    dispatch(showUserSaveMessage())
                    dispatch(getUsersListWithPaginationFilterAndSorting(userListSort || dataSort, jwtToken));
                    dispatch(getPhoneDictionary(jwtToken));
                } else if (response.data.statusCode === 304) {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                } else if (response.data.statusCode === 403) {
                    let errorMessage = {"mobilePhone": {name: response.data.message}};
                    dispatch(setUserErrorFields(errorMessage));
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                } else if (response.data.statusCode === 409) {
                    let errorMessage = {"email": {email: response.data.message}};
                    dispatch(setUserErrorFields(errorMessage));
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                } else {
                    dispatch(getUsersListWithPaginationFilterAndSorting(userListSort || dataSort, jwtToken));
                    dispatch(getUserDetails(data.userId, jwtToken));
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideUserSaveMessage());
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function deleteUser(data: any, jwtToken: any, sort?: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch({type: SET_USER_DELETE_MESSAGE_SUCCESS, payload: false})
        axios.put(`${API_URL}UsersManager/ArchiveUser`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then(() => {
                dispatch(getUsersListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                dispatch(setCurrentPageSize(10));
                dispatch(showUserDeleteMessage(true));
                dispatch({type: HIDE_USER_DELETE_MESSAGE})
                dispatch({type: SET_USER_DELETE_MESSAGE_SUCCESS, payload: true})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_DELETE_MESSAGE_SUCCESS, payload: false})
            })
    }
}

export function updateUserStatus(data: any, jwtToken: any, dataSort?: any, getLastStatus?: boolean, notShowNotify?: boolean) {
    return (dispatch: (arg0: any) => void) => {
        dispatch({type: SET_USER_STATUS_SUCCESS, payload: null})
        dispatch({type: SET_USER_STATUS_ERROR, payload: null})
        axios.post(`${API_URL}UsersManager/UpdateUserStatus`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    if (notShowNotify) {
                    } else {
                        dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    }
                    if (dataSort) {
                        dispatch(getUsersListWithPaginationFilterAndSorting(dataSort, jwtToken));
                        dispatch(getUserDetails(data.userId, jwtToken));
                    }
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    if (e.data) {
                        dispatch({type: SET_USER_STATUS_ERROR, payload: {message: e.data.message}})
                    } else {
                        dispatch({type: SET_USER_STATUS_ERROR, payload: {message: 'Щось пішло не так.'}})
                    }
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function getUserStatus(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.get(`${API_URL}Status/GetLastStatus`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS, payload: response.data.data})
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: e.data.message}})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function getUsersManagerBranchesList(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        axios.get(`${API_URL}UsersManager/GetUserBranchTree?isArchived=false`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                dispatch({type: GET_USER_BRANCHES_LIST, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideLoader())
            })
    }
}

export function getUsersManagerAllBranchesTree(jwtToken: any, archived: boolean) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showBranchLoader());
        dispatch(hideError());
        axios.get(`${API_URL}UsersManager/GetAllBranchesTree${!archived ? `?isArchived=false` : ''}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                dispatch({type: GET_BRANCHES, payload: response.data.data})
                dispatch(hideBranchLoader());
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideBranchLoader())
            });
    }
}

export function getUsersManagerRolesList(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.get(`${API_URL}UsersManager/GetAllRoles?isActive=true&isArchived=false`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    if (response.data.data) {
                        dispatch({type: GET_USER_ROLES, payload: response.data.data});
                    } else {
                        return dispatch(showRolesErrorMessage(true))
                    }
                } else {
                    return dispatch(showRolesErrorMessage(true))
                }
            })
            .catch(() => {
                return dispatch(showRolesErrorMessage(true))
            })

    }
}

export function getAllRolesList(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.get(`${API_URL}UsersManager/GetAllRoles?isActive=true&isArchived=false&allRole=true`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_USER_ALL_ROLES, payload: response.data.data});
                } else {
                    return dispatch(showRolesErrorMessage(true))
                }
            })
            .catch(() => {
                return dispatch(showRolesErrorMessage(true))
            })
            .finally(() => {
                return dispatch(showRolesErrorMessage(false))
            });
    }
}

export function getUsersDirectoriesGetTrunksPhones(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch({type: SHOW_GET_COMPANIES_DIRECTORIES_TRANKS_PHONE_ERROR, payload: false})
        axios.get(`${API_URL}IdentityDictionary/GetTrunksPhones`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                dispatch({type: GET_USERS_DIRECTORIES_TRUNKS_PHONE, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    return dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch({type: SHOW_GET_COMPANIES_DIRECTORIES_TRANKS_PHONE_ERROR, payload: true})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getBufferHash(username: string, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch({type: GET_HASH_LOADING, payload: true})
        axios.get(`${API_URL}UsersManager/GetBufferHash?username=${username}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    const copyToClipboard = (text: string) => {
                        const tempElement = document.createElement('textarea');
                        tempElement.value = text;
                        document.body.appendChild(tempElement);
                        tempElement.select();
                        document.execCommand('copy');
                        document.body.removeChild(tempElement);
                    }
                    copyToClipboard(response.data.data);
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    return dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: 'Щось пішло не так'}});
                }
            })
            .finally(() => {
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: GET_HASH_LOADING, payload: false});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function getPhoneDictionary(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.post(`${API_URL}IdentityDictionary/GetDictionary?isActive=true`, [400], {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_PHONES_DICTIONARY, payload: response.data.data});
                } else {
                    return dispatch(showRolesErrorMessage(true))
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    return dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showRolesErrorMessage(true))
                }
            })
            .finally(() => {
                dispatch(showRolesErrorMessage(false));
                return dispatch(showAccessDeniedNotifications(false));
            })

    }
}

export function getUserListExcel(dataSort: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch(showExportLoader(true));
        axios.post(`${API_URL}UsersManager/GetUsersToExcelWithData`, dataSort, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            },
            responseType: 'json'
        })
            .then((response) => {
                const fileName = response.data.data.fileDownloadName;
                const fileContentsBase64 = response.data.data.fileContents;

                const blob = base64ToBlob(fileContentsBase64, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showExportLoader(false));
                dispatch(showAccessDeniedNotifications(false));
                return dispatch(setUserError(false))
            })
    }
}

export function changeUserPassword(data: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch({type: CHANGE_PASSWORD_LOADING, payload: true});
        axios.post(`${API_URL}UsersManager/ChangeUserPassword`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch({type: CHANGE_PASSWORD_SUCCESS, payload: true});
                } else if (response.data.statusCode === 400) {
                    let errorMessage = {"passwordError": {name: response.data.message}};
                    dispatch(setUserErrorFields(errorMessage));
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: e.data.message}})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: CHANGE_PASSWORD_SUCCESS, payload: false});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch({type: CHANGE_PASSWORD_LOADING, payload: false});
            })
    }
}

export function changeUserPasswordByEmail(data: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch({type: CHANGE_PASSWORD_LOADING, payload: true});
        dispatch({type: CHANGE_PASSWORD_SUCCESS, payload: false});
        axios.post(`${API_URL}AccountWeb/ChangeUserPassword`, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch({type: CHANGE_PASSWORD_SUCCESS, payload: {message: response.data.message}});
                } else if (response.data.statusCode === 400) {
                    let errorMessage = {"passwordError": {name: response.data.message}};
                    dispatch(setUserErrorFields(errorMessage));
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: e.data.message}})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch({type: CHANGE_PASSWORD_LOADING, payload: false});
            })
    }
}

export function sendRecoveryPassword(data: any, currentUser?: boolean, closeRecoveryMessage?: boolean) {
    return (dispatch: (arg0: any) => void) => {
        dispatch({type: CHANGE_PASSWORD_LOADING, payload: true});
        dispatch({type: CHANGE_PASSWORD_SUCCESS, payload: false});
        axios.post(`${API_URL}AccountWeb/SendRecoveryPassword?usernameOrEmail=${data}`, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch({type: CHANGE_PASSWORD_SUCCESS, payload: {message: response.data.message}});
                    if (currentUser) {
                        dispatch({type: CHANGE_CURRENT_USER_PASSWORD_SUCCESS, payload: {message: response.data.message}});
                    }
                } else if (response.data.statusCode === 400) {
                    let errorMessage = {"recoveryEmailError": {name: response.data.message}};
                    dispatch(setUserErrorFields(errorMessage));
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}});
                    let errorMessage = {"name": {name: response.data.message}};
                    dispatch(setUserErrorFields(errorMessage));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: e.response.data.title || 'Щось пішло не так'}});
                    let errorMessage = {"name": {name: e.response.data.title || 'Щось пішло не так'}};
                    dispatch(setUserErrorFields(errorMessage));
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch({type: CHANGE_PASSWORD_LOADING, payload: false});
                if (closeRecoveryMessage) {
                    dispatch({type: CHANGE_CURRENT_USER_PASSWORD_SUCCESS, payload: false});
                }
            })
    }
}

export function checkRecoveryPassword(id: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(googleAuthLoginError(false));
        axios.get(`${API_URL}AccountWeb/ValidateRecoveryPassword?id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                if (response.data.statusCode !== 200) {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}});
                    let errorMessage = {"name": {name: response.data.message}};
                    dispatch(setUserErrorFields(errorMessage));
                    dispatch(googleAuthLoginError(response?.data?.message || true));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: e.response.data.title}});
                    dispatch(googleAuthLoginError(e?.response?.data?.title || true));
                    let errorMessage = {"name": {name: e.response.data.title}};
                    dispatch(setUserErrorFields(errorMessage));
                    dispatch(googleAuthLoginError(e.response?.data?.title || true));
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function getTechUserPermissionsDictionary(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.post(`${API_URL}IdentityDictionary/GetDictionary?isActive=true`, [600], {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    if (response.data.data) {
                        dispatch({type: GET_TECH_USER_DICTIONARY, payload: response.data.data});
                    } else {
                        return dispatch(showRolesErrorMessage(true))
                    }
                } else {
                    return dispatch(showRolesErrorMessage(true))
                }
            })
            .catch(() => {
                return dispatch(showRolesErrorMessage(true))
            })

    }
}

export function createTechUser(data: any, jwtToken: any, branchId: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch({type: TECH_USER_LOADING, payload: true});
        dispatch({type: SUCCESS_CREATED_TECH_USER, payload: false});
        axios.post(`${API_URL}Branch/CreateTechUser`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    console.log(response.data)
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getBranchDetailById(branchId, jwtToken));
                    dispatch({type: SUCCESS_CREATED_TECH_USER, payload: true});
                } else if (response.data.statusCode === 410) {
                        let errorMessage = {"userName": {name: response.data.message}};
                        dispatch(setBranchErrorFields(errorMessage));
                } else if (response.data.statusCode === 409) {
                    let errorMessage = {"password": {name: response.data.message}};
                    dispatch(setBranchErrorFields(errorMessage));
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: e.data.message}})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch({type: TECH_USER_LOADING, payload: false});
                dispatch({type: SUCCESS_CREATED_TECH_USER, payload: false});
            })
    }
}

export function updateTechUser(data: any, jwtToken: any, branchId: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch({type: TECH_USER_LOADING, payload: true});
        dispatch({type: SUCCESS_CREATED_TECH_USER, payload: false});
        axios.post(`${API_URL}Branch/UpdateTechUser`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getBranchDetailById(branchId, jwtToken));
                    dispatch({type: SUCCESS_CREATED_TECH_USER, payload: true});
                } else if (response.data.statusCode === 400) {
                    if (response.data.title === 'UserNameExist') {
                        let errorMessage = {"userName": {name: response.data.message}};
                        dispatch(setBranchErrorFields(errorMessage));
                    } else {
                        let errorMessage = {"passwordError": {name: response.data.message}};
                        dispatch(setBranchErrorFields(errorMessage));
                    }
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: e.data.message}})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SUCCESS_CREATED_TECH_USER, payload: false});
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch({type: TECH_USER_LOADING, payload: false});
            })
    }
}

export function archiveTechUser(userId: any, jwtToken: any, branchId: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch({type: TECH_USER_LOADING, payload: true});
        axios.post(`${API_URL}Branch/DeleteTechUser?userId=${userId}`, {},{
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getBranchDetailById(branchId, jwtToken));
                } else if (response.data.statusCode === 400) {
                    if (response.data.title === 'UserNameExist') {
                        let errorMessage = {"userName": {name: response.data.message}};
                        dispatch(setBranchErrorFields(errorMessage));
                    } else {
                        let errorMessage = {"passwordError": {name: response.data.message}};
                        dispatch(setBranchErrorFields(errorMessage));
                    }
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: e.data.message}})
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
                dispatch({type: TECH_USER_LOADING, payload: false});
            })
    }
}
