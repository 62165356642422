import axios from "axios";
import {API_URL} from "../config";
import {GET_ROLE_DETAILS, GET_ROLE_PAGINATION, GET_ROLES_DICTIONARY, GET_ROLES_LIST, GET_ROLES_VISION, GET_TECH_USER_DICTIONARY, GET_TRUNKS_DICTIONARY, HIDE_ROLE_ARCHIVED_MESSAGE, SET_ROLE_ARCHIVED_MESSAGE_SUCCESS} from "../types/typesRoles";
import {hideRoleDetailsLoader, hideRoleSaveMessage, hideRolesListLoader, setCurrentPageSize, setRolesVisible, showRoleDeleteMessage, showRoleDetailsListLoader, showRoleSaveMessage, showRolesErrorMessage, showRolesListLoader} from "../actions/actionsRoles";
import {setUserError} from "../actions/actionsUsersList";
import {SET_USER_STATUS_ERROR, SET_USER_STATUS_SUCCESS} from "../types/typesUsersList";
import {showAccessDeniedNotifications} from "../actions/actionsNotifications";
import {GET_NOTIFICATION_ERROR} from "../types/types";
import {hideError} from "../actions/actionsMonitoringList";
import {RoleDataSort} from "../../types/types";
import {hideErrorMessage, showError} from "../actions/actions";
import {getBranchDetailById} from "./apiBranchList";
export function getRoleVision(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showRolesErrorMessage(false))
        axios.get(`${API_URL}Role/GetRoleVisible`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    if (response.data.data) {
                        dispatch({type: GET_ROLES_VISION, payload: response.data.data});
                        localStorage.setItem('callcenter:role_vision', JSON.stringify(response.data.data));
                        dispatch(showRolesErrorMessage(false))
                    }
                    else {
                        return dispatch(showRolesErrorMessage(true))
                    }
                }
                else {
                    return dispatch(showRolesErrorMessage(true))
                }
            })
            .catch(() => {
                return dispatch(showRolesErrorMessage(true))
            })

    }
}

export function getRolesList(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.get(`${API_URL}Role/GetAllRoles?isActive=true&isArchived=false`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    if (response.data.data) {
                        dispatch({type: GET_ROLES_LIST, payload: response.data.data});
                    }
                    else {
                        return dispatch(showRolesErrorMessage(true))
                    }
                }
                else {
                    return dispatch(showRolesErrorMessage(true))
                }
            })
            .catch(() => {
                return dispatch(showRolesErrorMessage(true))
            })

    }
}

export function getRolesListWithPaginationFilterAndSorting(data: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showRolesListLoader());
        dispatch(hideError());
        dispatch(setUserError(false));
        axios.post(`${API_URL}Role/GetAllRolesWithPagination`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                dispatch({type: GET_ROLES_LIST, payload: response.data.data.items})
                dispatch({type: GET_ROLE_PAGINATION, payload: response.data.data})
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideRolesListLoader())
            })
    }
}

export function getRoleDetails(id: number, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(showRoleDetailsListLoader());
        axios.get(`${API_URL}Role/GetRoleMatrixById?roleId=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    if (response.data.data) {
                        dispatch({type: GET_ROLE_DETAILS, payload: response.data.data});
                    }
                    else {
                        return dispatch(showRolesErrorMessage(true))
                    }
                }
                else {
                    return dispatch(showRolesErrorMessage(true))
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showRolesErrorMessage(true))
                }
            })
            .finally(() => {
            dispatch(showAccessDeniedNotifications(false));
            dispatch(hideRoleDetailsLoader());
        })

    }
}

export function getRolesDictionary(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.post(`${API_URL}IdentityDictionary/GetDictionary?isActive=true`, [200], {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    if (response.data.data) {
                        dispatch({type: GET_ROLES_DICTIONARY, payload: response.data.data});
                    }
                    else {
                        return dispatch(showRolesErrorMessage(true))
                    }
                }
                else {
                    return dispatch(showRolesErrorMessage(true))
                }
            })
            .catch(() => {
                return dispatch(showRolesErrorMessage(true))
            })

    }
}

export function getTrunksDictionary(jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.post(`${API_URL}IdentityDictionary/GetDictionary?isActive=true`, [500], {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    if (response.data.data) {
                        dispatch({type: GET_TRUNKS_DICTIONARY, payload: response.data.data});
                    }
                    else {
                        return dispatch(showRolesErrorMessage(true))
                    }
                }
                else {
                    return dispatch(showRolesErrorMessage(true))
                }
            })
            .catch(() => {
                return dispatch(showRolesErrorMessage(true))
            })

    }
}


const dataSort: RoleDataSort = {
    "isArchived": false,
    "pageNumber": 1,
    "pageSize": 10,
    "sort": {
        "orderBy": "Id",
        "isDesc": true
    },
    "filter": []
}
export function updateRole(data: any, jwtToken: any, sort? : RoleDataSort, branchId?: number) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideRoleSaveMessage());
        dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
        dispatch(setUserError(false));
        axios.post(`${API_URL}Role/UpdateRole`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_ROLE_DETAILS, payload: response.data.data})
                    dispatch(showRoleSaveMessage());
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getRolesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    if (branchId) {
                        dispatch(getBranchDetailById(branchId, jwtToken));
                    }
                } else {
                    dispatch(getRolesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideRoleSaveMessage());
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function createRole(data: any, jwtToken: any, sort? : RoleDataSort) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideRoleSaveMessage());
        dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
        dispatch(setUserError(false));
        axios.post(`${API_URL}Role/CreateRole`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: GET_ROLE_DETAILS, payload: response.data.data})
                    dispatch(showRoleSaveMessage());
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getRolesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                } else {
                    dispatch(getRolesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch(hideRoleSaveMessage());
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function archiveRole(id: any, jwtToken: any, sort?: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError())
        dispatch(hideErrorMessage())
        dispatch({type: SET_ROLE_ARCHIVED_MESSAGE_SUCCESS, payload: false})
        dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
        axios.post(`${API_URL}Role/ArchiveOrUnArchiveRole?roleId=${id}`,{}, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                dispatch(getRolesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                dispatch(setCurrentPageSize(10));
                dispatch(showRoleDeleteMessage(true));
                dispatch({type: HIDE_ROLE_ARCHIVED_MESSAGE})
                dispatch({type: SET_ROLE_ARCHIVED_MESSAGE_SUCCESS, payload: true})
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload:  {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_ROLE_ARCHIVED_MESSAGE_SUCCESS, payload: false});
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function copyRoleById(roleId: number, jwtToken: any, sort?: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(hideError());
        axios.get(`${API_URL}Role/CopyRole/${roleId}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getRolesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                } else {
                    dispatch(getRolesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch(showError())
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}

export function setArchiveRoleById(id: number, jwtToken: any, sort?: any) {
    return (dispatch: (arg0: any) => void) => {
        dispatch(setUserError(false));
        axios.post(`${API_URL}Role/ArchiveOrUnArchiveQueue?queueId=${id}`, {}, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`,
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch({type: SET_USER_STATUS_SUCCESS, payload: {message: response.data.message}});
                    dispatch(getRolesListWithPaginationFilterAndSorting(sort || dataSort, jwtToken));
                    dispatch(setRolesVisible(false));
                } else {
                    dispatch({type: SET_USER_STATUS_ERROR, payload: {message: response.data.message}})
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch({type: GET_NOTIFICATION_ERROR, payload: true});
                    return dispatch(setUserError(true))
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
                dispatch({type: SET_USER_STATUS_SUCCESS, payload: null});
                dispatch({type: SET_USER_STATUS_ERROR, payload: null});
            })
    }
}
