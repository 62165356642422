import {InputText} from 'primereact/inputtext';
import {Dropdown} from "primereact/dropdown";
import {classNames} from "primereact/utils";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import React, {useEffect, useState} from "react";
import {getBranchesACD} from "../../../redux/api/apiBranchList";
import {setBranchErrorFields, setBranchField} from "../../../redux/actions/actionsBranchList";
import {Button} from "primereact/button";
import {InputSwitch} from "primereact/inputswitch";
import {Divider} from "primereact/divider";
import {Dialog} from "primereact/dialog";
import {MultiSelect} from "primereact/multiselect";
import {archiveTechUser, createTechUser, getTechUserPermissionsDictionary, getUserDetails, updateTechUser} from "../../../redux/api/apiUsersList";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {validateTechUserBranchForm} from "../../../redux/validator/validator";
import {ConfirmDialog} from "primereact/confirmdialog";

const BranchACDSettings = () => {
    const dispatch = useAppDispatch();
    const branchesACD = useAppSelector(state => state.BranchesListReducer.branchesACD);
    const branchData = useAppSelector(state => state.BranchesListReducer.branchData);
    const errorFields = useAppSelector(state => state.BranchesListReducer.errorFields);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [visible, setVisible] = useState(false);
    const [id, setId] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    // @ts-ignore
    const techUserDictionary = useAppSelector(state => state.UsersListReducer.techUserDictionary);
    // @ts-ignore
    const successCreated = useAppSelector(state => state.UsersListReducer.successCreated);
    useEffect(() => {
        dispatch(getBranchesACD(jwtToken?.jwtToken));
        dispatch(getTechUserPermissionsDictionary(jwtToken?.jwtToken));
    }, []);
    const [newTechUser, setNewTechUser] = useState<any>({
        userName: '',
        isActive: false,
        password: '',
        techUserPermissions: []
    });

    useEffect(() => {
        if (successCreated) {
            setNewTechUser({
                userName: '',
                isActive: false,
                password: '',
                techUserPermissions: []
            });
            dispatch(setBranchErrorFields(null));
            setVisible(false);
        }
    }, [successCreated]);

    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const branchPermissions = checkPermissions(rolesVision, 'Branch');

    const handleChange = (field: any, value: any) => {
        setNewTechUser((prevState: any) => ({
            ...prevState,
            [field]: value
        }));
    };

    const {
        // create: hasBranchCreatePermission,
        update: hasBranchUpdatePermission,
    } = branchPermissions;

    const foundACD = branchesACD?.find((item: { id: any; }) => item.id === branchData?.integrationURLId);

    const createUserData = (data: any) => {
        const validationErrors = validateTechUserBranchForm(newTechUser);
        dispatch(setBranchErrorFields(validationErrors));
        if (!validationErrors) {
            dispatch(createTechUser(data, jwtToken?.jwtToken, branchData?.id));
        }
    };

    const updateUserData = (data: any) => {
        const validationErrors = validateTechUserBranchForm(newTechUser);
        dispatch(setBranchErrorFields(validationErrors));
        if ((!validationErrors || validationErrors.password) && !validationErrors?.userName && !validationErrors?.techUserPermissions && !validationErrors?.isActive) {
            dispatch(updateTechUser(data, jwtToken?.jwtToken, branchData?.id));
        }
    };

    const getIntegrationName = (permissions: any) => {
        const names = techUserDictionary
            ?.filter((item: { dictionaryId: any; }) => permissions.includes(item.dictionaryId))
            ?.map((item: { name: any; }) => item?.name);

        return names.join(', ');
    }

    const removePair = (userId: any) => {
            dispatch(archiveTechUser(userId, jwtToken?.jwtToken, branchData?.id))
    };

    return (
        <div className="grid p-fluid">
            {branchData?.id !== branchData?.mainBranchId &&<div className="field col-12 md:col-12 mb-0">
                <label htmlFor="name" className="font-bold">Інтегратор</label>
                <div className="field-radiobutton my-3">
                    <label htmlFor="name" className="mr-2">Загальний інтегратор</label>
                    <InputSwitch
                        disabled={branchData?.isArchived || !editType}
                        // disabled
                        checked={!branchData.isMainIntegrationUrl} onChange={(e) => {
                        dispatch(setBranchField({fieldName: 'isMainIntegrationUrl', value: !e.value}));
                        if (!e.value) {
                            dispatch(setBranchField({fieldName: 'integrationURLId', value: 0}));
                        }
                    }}/>
                    <label htmlFor="icon">Персональний інтегратор</label>
                </div>
            </div>}
            {branchData?.id !== branchData?.mainBranchId &&<Divider/>}
            <div className="field col-12 md:col-4">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.parentName && !branchData?.parentName})}>Url Name</label>
                <Dropdown
                    value={branchData?.integrationURLId || ''}
                    onChange={(e) => {
                        dispatch(setBranchField({fieldName: 'integrationURLId', value: e.target.value}))
                    }}
                    options={branchesACD}
                    optionLabel="urlName"
                    optionValue="id"
                    filter
                    placeholder="Виберіть:"
                    disabled={branchData?.isArchived || !editType || branchData.isMainIntegrationUrl}
                    // disabled
                />
            </div>12
            <div className="field col-10 md:col-6">
                <label htmlFor="name" className={classNames('', {'p-error': errorFields?.name && !branchData?.name})}>Integration Url</label>
                <InputText
                    id="name"
                    value={foundACD?.integertionUrl || ''}
                    // className={`${branchData.isMainIntegrationUrl ? 'opacity-50' : 'opacity-100'}`}
                    disabled={branchData?.isArchived || !editType || branchData.isMainIntegrationUrl}
                />
            </div>
            <div className="field col-2 md:col-2 align-self-end flex justify-content-end md:justify-content-start">
                {!!branchData?.integrationURLId &&
                    <Button
                        onClick={(e) => {
                            dispatch(setBranchField({fieldName: 'integrationURLId', value: 0}))
                        }}
                        icon="pi pi-times"
                        className="delete-user p-button-secondary p-button-text field col-12 md:col-2 m-0"
                        style={{backgroundColor: 'rgba(255, 255, 255, 0.05)'}}
                        disabled={branchData?.isArchived || !editType || branchData.isMainIntegrationUrl}
                    />}
            </div>
            {branchData?.id === branchData?.mainBranchId && <Divider/>}
            {branchData?.id === branchData?.mainBranchId &&<div className="field col-12 md:col-12 align-self-end flex justify-content-end md:justify-content-start mb-1">
                <div className="flex col-3 md:col-3 p-0"><p className="pr-2 m-0 font-bold">Технічний Акаунт</p></div>
                <div className="flex col-5 md:col-5 p-0 mr-2"><p className="pr-2 m-0 font-bold">Інтеграція</p></div>
                <div className="flex col-1 md:col-2 p-0"><p className="pr-2 m-0 font-bold">Стан</p></div>
                <div className="flex col-3 md:col-2 p-0"><p className="pr-2 m-0 font-bold">Стан</p></div>
            </div>}
            {branchData?.id === branchData?.mainBranchId &&<div className="field col-12 md:col-12 align-self-end flex justify-content-end md:justify-content-start flex-column p-0">
                {branchData?.techUsers?.length && branchData?.techUsers?.map((user: any, index: number) => (
                    <React.Fragment key={index}>
                        <ul className="field col-12 md:col-12 flex align-items-center m-0 w-full">
                            <li className="w-full flex align-items-center">
                                <div className="flex col-3 md:col-3 p-0">
                                    {hasBranchUpdatePermission && <Button
                                        className="max-w-4rem p-button-secondary mr-2"
                                        style={{minWidth: '40px'}}
                                        disabled={branchData?.isArchived}
                                        icon={hasBranchUpdatePermission ? 'pi pi-pencil' : !hasBranchUpdatePermission ? 'pi pi-eye' : ''}
                                        onClick={() => {
                                            if (hasBranchUpdatePermission) {
                                                setVisible(true);
                                                setNewTechUser(user);
                                                handleChange('password', null)
                                            }
                                        }}/>}
                                    <div className="flex col-3 md:col-3 p-0 align-items-center"><p className="pr-2 m-0">{user.userName}</p></div>
                                </div>
                                <div className="flex col-5 md:col-5 p-0 mr-2">
                                    <InputText value={getIntegrationName(user?.techUserPermissions)} disabled={true} className="mr-2 opacity-100"/>
                                </div>
                                <div className="flex col-3 md:col-2 p-0"><p className="pr-2 m-0">{user?.isActive ? 'Активний' : 'Не активний'}</p></div>
                                <div className="flex col-3 md:col-2 p-0"><Button icon="pi pi-times" className="p-button-secondary"
                                                                                 disabled={branchData?.isArchived || !editType}
                                                                                 onClick={() => {
                                                                                     setId(user.userId);
                                                                                     setDeleteDialogVisible(true);
                                                                                 }}/></div>
                            </li>
                        </ul>
                    </React.Fragment>
                ))}
            </div>}
            {branchData?.id === branchData?.mainBranchId &&<div className="field col-4 md:col-4 align-self-end flex justify-content-end md:justify-content-start">
                <Button
                    onClick={(e) => {
                        // dispatch(setBranchField({fieldName: 'integrationURLId', value: 0}))
                        setVisible(true);
                    }}

                    label="Додати Акаунт"
                    icon="pi pi-plus"
                    className="max-w-11rem"
                    disabled={branchData?.isArchived || !editType}
                />
                <Dialog header="Технічний аккаунт" visible={visible} style={{maxWidth: '320px'}} onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}>
                    <div className="field col-12 md:col-12">
                        <div className="field col-12 md:col-12 flex flex-column mb-0">
                            <label htmlFor="name" className={classNames('', {'p-error': errorFields?.userName || errorFields?.userNameError})}>Логін *</label>
                            {!newTechUser?.userId && <InputText
                                id="username"
                                value={newTechUser?.userName || ''}
                                maxLength={32}
                                className={classNames('', {'p-invalid': errorFields?.userName || errorFields?.userNameError})}
                                onChange={(e) => handleChange('userName', e.target.value)}
                                disabled={branchData?.isArchived || (!editType)}
                            />}
                            {newTechUser?.userId && <InputText
                                id="username"
                                value={newTechUser?.userName || ''}
                                maxLength={32}
                                className={classNames('', {'p-invalid': errorFields?.userName || errorFields?.userNameError})}
                                onChange={(e) => handleChange('userName', e.target.value)}
                                disabled
                            />}
                            {errorFields && errorFields?.userName && errorFields?.userName?.name && <label htmlFor="address" className={classNames('mt-2 text-xs inline', {'p-error': errorFields?.userName})}>{errorFields?.userName?.name}</label>}
                            {errorFields && errorFields?.userNameError && <label htmlFor="address" className={classNames('mt-2 text-xs inline', {'p-error': errorFields?.userNameError})}>{errorFields?.userNameError}</label>}
                        </div>
                        <div className="field col-12 md:col-12 flex flex-column mb-0">
                            <label htmlFor="password" className={classNames('', {'p-error': errorFields?.password})}>Пароль *</label>
                            <InputText
                                value={newTechUser?.password || ''}
                                maxLength={90}
                                className={classNames('', {'p-invalid': errorFields?.password})}
                                onChange={(e) => handleChange('password', e.target.value)}
                                disabled={branchData?.isArchived || (!editType)}
                            />
                            {errorFields && errorFields?.password && errorFields?.password && <label htmlFor="address" className={classNames('mt-2 text-xs inline', {'p-error': errorFields?.password})}>{errorFields?.password?.password}</label>}
                        </div>
                        <div className="field col-12 md:col-12 flex flex-column mb-0">
                            <label htmlFor="password" className={classNames('', {'p-error': errorFields?.techUserPermissions})}>Інтеграція *</label>

                            <MultiSelect
                                value={newTechUser?.techUserPermissions || ''}
                                onChange={(e) => handleChange('techUserPermissions', e.value)}
                                options={techUserDictionary}
                                optionValue="dictionaryId"
                                optionLabel="name"
                                className={classNames('', {'p-invalid': errorFields?.techUserPermissions})}
                                disabled={branchData?.isArchived || (!editType)}
                            />
                        </div>
                        <div className="field col-12 md:col-12">
                            <label htmlFor="name">Стан</label>
                            <div className="field-radiobutton my-2 pl-1">
                                <label htmlFor="name" className="mr-2 w-6rem">Не активна</label>
                                <InputSwitch
                                    disabled={branchData?.isArchived || !editType}
                                    className="mr-3"
                                    checked={newTechUser?.isActive}
                                    onChange={(e) => handleChange('isActive', e.value)}
                                />
                                <label htmlFor="icon">Активна</label>
                            </div>
                        </div>
                        <div className="field col-12 md:col-12 flex justify-content-between">
                            <Button
                                label="Відміна"
                                icon="pi pi-ban"
                                className="p-button-outlined"
                                onClick={() => {
                                    setVisible(false);
                                    dispatch(setBranchErrorFields(null));
                                    setNewTechUser({
                                        userName: '',
                                        isActive: false,
                                        password: '',
                                        techUserPermissions: []
                                    })
                                }}
                            />
                            {!newTechUser?.userId && <Button
                                icon="pi pi-save"
                                label="Створити"
                                onClick={() => createUserData(newTechUser)}
                            />}
                            {newTechUser?.userId && <Button
                                icon="pi pi-save"
                                label="Зберегти"
                                onClick={() => updateUserData(newTechUser)}
                            />}
                        </div>
                    </div>
                </Dialog>
                <ConfirmDialog visible={deleteDialogVisible} onHide={() => setDeleteDialogVisible(false)} message={'Технічний Акаунт буде видалений.\n' +
                    'Продовжити ?'}
                               icon="pi pi-exclamation-triangle" acceptLabel="Так" rejectLabel="Відмінити"
                               accept={() => removePair(id)}
                />
            </div>}
        </div>
    );
};

export default BranchACDSettings;
