import {MultiSelect} from "primereact/multiselect";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {Skeleton} from "primereact/skeleton";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import React, {useRef, useState} from "react";
import {InputSwitch} from "primereact/inputswitch";
import {addQueueSkillGroup, addQueueUser, addQueueUtilizationGroup, changeQueueSkillGroupLevel, changeQueueUserLevel, changeQueueUtilizationGroupIndex, deleteQueueSkillGroup, deleteQueueUser, deleteQueueUtilizationGroup, setQueueField} from "../../../redux/actions/actionsQueue";
import {InputNumber} from "primereact/inputnumber";
import {classNames} from "primereact/utils";
import {PROD} from "../../../redux/config";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import UserListDialog from "../../Settings/UsersList/UserDialod";
import {setEditType} from "../../../redux/actions/actions";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {getUserDetails} from "../../../redux/api/apiUsersList";
import QualificationGroupDialog from "../../QualificationGroupList/QualificationGroupDialog";
import {getQualificationGroupDetails} from "../../../redux/api/apiQualificationGroup";

const QueueListDialogUsers = () => {
    const dispatch = useAppDispatch()
    // @ts-ignore
    const usersData = useAppSelector(state => state.CompaniesListReducer.usersData);
    // @ts-ignore
    const loadingStrategyDict = useAppSelector(state => state.CompaniesListReducer.loadingStrategyDict)
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.QueueListReducer.archiveQueueTableToggle)
    // @ts-ignore
    const errorFields = useAppSelector(state => state.QueueListReducer.errorFields);
    // @ts-ignore
    const queueData = useAppSelector(state => state.QueueListReducer.queueData);
    // @ts-ignore
    const queuesDirectoryQueueOperatorsType = useAppSelector(state => state.QueueListReducer.queuesDirectoryQueueOperatorsType);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const inputRef = useRef<any>(null);
    const skillGroupList = useAppSelector(state => state.QualificationGroupListReducer.skillGroupList);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const usersManagerPermissions = checkPermissions(rolesVision, 'UsersManager');
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const detailsLoading = useAppSelector(state => state.UsersListReducer.detailLoading);
    const skillGroupDetailsLoading = useAppSelector(state => state.QualificationGroupListReducer.detailsLoading);
    const rolePermissions = checkPermissions(rolesVision, 'SkillGroupManager');
    const skillGroupPermissions = checkPermissions(rolesVision, 'SkillGroupManager');
    const [currentUserId, setCurrentUserId] = useState<any>(null);
    const {
        update: hasUsersManagerUpdatePermission,
        visible: hasUsersManagerVisiblePermission
    } = usersManagerPermissions;

    const {
        // update: hasUpdatePermission,
    } = rolePermissions;

    const {
        visible: hasRoleVisionPermission,
        update: hasRoleUpdatePermission,
    } = skillGroupPermissions;


    const usersDataWithUserLevel = usersData?.map((userData: any) => {
        return {
            ...userData,
            userLevel: 0,
            fullName: `${userData?.lastName} ${userData?.firstName} ${userData?.middleName ? ` ${userData.middleName}` : ''}`
        }
    });

    const userLevels: any = {};
    queueData?.queueUsers?.forEach((user: { userId: string | number; userLevel: any; }) => {
        userLevels[user.userId] = user.userLevel;
    });

    usersDataWithUserLevel?.forEach((user: { userId: string | number; userLevel: any; }) => {
        if (userLevels[user.userId] !== undefined) {
            user.userLevel = userLevels[user.userId];
        }
    });

    const skillGroupUsers = queueData?.skillGroups?.map((companiesItem: any) => {
        const user = skillGroupList.find((userItem: any) => userItem.id === companiesItem.skillGroupId);
        const {...userWithoutCampaignId} = companiesItem;
        if (queueData?.queue?.id) {
            return {
                ...userWithoutCampaignId,
                skillGroupId: user?.id,
                id: user?.id,
                name: user?.name,
                queueId: queueData?.queue?.id
            }
        } else {
            return {
                ...userWithoutCampaignId,
                skillGroupId: user?.id,
                id: user?.id,
                name: user?.name,
            }
        }
    })

    // @ts-ignore
    const skillGroupDataWithLevel = skillGroupList?.map(({isActive, ...data}) => {
        if (queueData?.queue?.id) {
            return {
                ...data,
                skillGroupLevel: 0,
                skillGroupId: data.id,
                queueId: queueData?.queue?.id
            };
        } else {
            return {
                ...data,
                skillGroupLevel: 0,
                skillGroupId: data.id,
            };
        }
    });

    const filteredSkillGroups = skillGroupUsers?.filter((skillGroup: { skillGroupId: any; }) =>
        skillGroupDataWithLevel?.some((levelGroup: { id: any; }) => levelGroup.id === skillGroup.skillGroupId)
    ) || [];

    const filteredUsers = queueData?.queueUsers?.filter((skillGroup: { userId: any; }) =>
        usersDataWithUserLevel?.some((levelGroup: { userId: any; }) => levelGroup.userId === skillGroup.userId)
    ) || [];

    const userItems = queueData?.queueUsers?.map((queueItem: any) => {
        return usersDataWithUserLevel?.map((userItem: any, index: number) => {
            if (queueItem.userId === userItem.userId) {
                const userLevelError = errorFields?.queueUsers?.queueUsersWithoutLevel?.find((user: { userId: any; }) => user.userId === queueItem.userId);

                return (
                    <div key={index} className="flex align-items-center mb-2 p-0">
                        {hasUsersManagerVisiblePermission && <Button
                            className="max-w-4rem p-button-secondary mr-3"
                            style={{minWidth: '40px'}}
                            loading={detailsLoading && currentUserId === userItem.userId}
                            disabled={!archiveTableToggle || detailsLoading}
                            icon={archiveTableToggle && hasUsersManagerUpdatePermission ? 'pi pi-pencil' : !archiveTableToggle || !hasUsersManagerUpdatePermission ? 'pi pi-eye' : ''}
                            onClick={() => {
                                setCurrentUserId(userItem.userId);
                                dispatch(getUserDetails(userItem.userId, jwtToken?.jwtToken, true));
                                if (hasUsersManagerUpdatePermission) {
                                    dispatch(setEditType(true));
                                } else {
                                    dispatch(setEditType(false));
                                }
                            }}/>}
                        <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                            {userItem.lastName} {userItem.firstName} {userItem.middleName}
                        </div>
                        <InputNumber
                            ref={inputRef}
                            value={userItem?.userLevel}
                            className={classNames('', {'p-invalid': errorFields?.queueUsers && userLevelError})}
                            disabled={!archiveTableToggle || !editType}
                            min={0} max={1000}
                            useGrouping={false}
                            onChange={(e) => dispatch(changeQueueUserLevel({queueId: queueData?.queue?.id, userId: userItem.userId, userLevel: e.value}))}
                        />
                        <Button name={userItem.userId}
                                onClick={() => {
                                    dispatch(deleteQueueUser(userItem.userId));
                                }}
                                icon="pi pi-times"
                                className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2"
                                disabled={!archiveTableToggle || !editType}
                        />
                    </div>
                )
            }
        })
    })

    function randomNumber(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const campaignStrategyItemTemplate = (option: any) => {
        let typeName = queuesDirectoryQueueOperatorsType.map((item: { dictionaryId: number; name: any; }) => {
            if (item.dictionaryId === option) {
                return item.name
            }
        })
        return (
            <div className="p-multiselect-representative-option">
                {loadingStrategyDict && <Skeleton width={`${randomNumber(50, 85)}%`} height="1rem"/>}
                {!loadingStrategyDict && <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {typeName}
                </span>}
            </div>
        );
    }

    const selectedCampaignStrategyTemplate = (option: any) => {
        let typeName = queuesDirectoryQueueOperatorsType.map((item: { dictionaryId: number; name: any; }) => {
            if (item.dictionaryId === option) {
                return item.name
            }
        })
        if (option) {
            return (
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {typeName}
                </span>
            );
        }

        return "Оберіть тип";
    }

    const usersDataItemTemplate = (option: any) => {
        return usersDataWithUserLevel.map((item: any) => {
            if (item.userId === option.userId) {
                return `${item.lastName} ${item.firstName} ${item?.middleName ? ` ${item.middleName}` : ''}`
            }
        })
    }

    const campaignUsers = queueData?.queueUsers?.map((companiesItem: any) => {
        const user = usersData.find((userItem: any) => userItem.userId === companiesItem.userId);
        const {queueId, ...userWithoutCampaignId} = companiesItem;
        return {
            ...userWithoutCampaignId,
            lastName: user?.lastName,
            firstName: user?.firstName,
            middleName: user?.middleName,
            userName: user?.userName,
            fullName: `${user?.lastName} ${user?.firstName} ${user?.middleName ? ` ${user.middleName}` : ''}`,
            userId: user?.userId,
            isActive: user?.isActive
        }
    });

    const skillGroupLevels: any = {};
    queueData?.skillGroups?.forEach((user: { skillGroupId: string | number; skillGroupLevel: any; }) => {
        skillGroupLevels[user.skillGroupId] = user.skillGroupLevel;
    });

    skillGroupDataWithLevel.forEach((user: { skillGroupId: string | number; skillGroupLevel: any; }) => {
        if (skillGroupLevels[user.skillGroupId] !== undefined) {
            user.skillGroupLevel = skillGroupLevels[user.skillGroupId];
        }
    });

    const skillGroupDataItemTemplate = (option: any) => {
        return skillGroupList.map((item: any) => {
            if (item.id === option.skillGroupId) {
                return item.name
            }
        })
    }

    const skillGroupItems = queueData?.skillGroups?.map((item: any) => {
        return skillGroupDataWithLevel.map((skillGroup: any, index: number) => {
            if (item.skillGroupId === skillGroup.skillGroupId) {
                const userLevelError = errorFields?.campaignUsers?.campaignUsersWithoutLevel?.find((user: { userId: any; }) => user.userId === item.userId);

                return (
                    <div key={index} className="flex align-items-center mb-2">
                        {hasRoleVisionPermission && <Button
                            className="max-w-4rem p-button-secondary mr-3"
                            style={{minWidth: '40px'}}
                            loading={skillGroupDetailsLoading && currentUserId === skillGroup.skillGroupId}
                            disabled={!archiveTableToggle || skillGroupDetailsLoading}
                            icon={archiveTableToggle && hasRoleUpdatePermission ? 'pi pi-pencil' : !archiveTableToggle || !hasRoleUpdatePermission ? 'pi pi-eye' : ''}
                            onClick={() => {
                                setCurrentUserId(skillGroup.skillGroupId);
                                dispatch(getQualificationGroupDetails(skillGroup.skillGroupId, jwtToken?.jwtToken, true));
                                if (hasRoleUpdatePermission) {
                                    dispatch(setEditType(true));
                                } else {
                                    dispatch(setEditType(false));
                                }
                            }}/>}
                        <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                            {skillGroup.name}
                        </div>
                        <InputNumber
                            ref={inputRef}
                            value={skillGroup?.skillGroupLevel}
                            className={classNames('', {'p-invalid': errorFields?.campaignUsers && userLevelError})}
                            disabled={!archiveTableToggle || !editType}
                            min={0} max={1000}
                            useGrouping={false}
                            onChange={(e) => dispatch(changeQueueSkillGroupLevel({queueId: queueData?.id, skillGroupId: skillGroup.skillGroupId, skillGroupLevel: e.value}))}
                        />
                        <Button name={skillGroup.skillGroupId}
                                onClick={() => {
                                    dispatch(deleteQueueSkillGroup(skillGroup.skillGroupId));
                                }}
                                icon="pi pi-times"
                                className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2"
                                disabled={!archiveTableToggle || !editType}
                        />
                    </div>
                )
            }
        })
    });

    // @ts-ignore
    const utilizationGroupDataWithLevel = skillGroupList?.map(({isActive, ...data}) => {
        if (queueData?.queue?.id) {
            return {
                ...data,
                skillGroupId: data.id,
                queueId: queueData?.queue?.id
            };
        } else {
            return {
                ...data,
                skillGroupId: data.id
            };
        }
    });

    const displayOrders: any = {};
    queueData?.skillGroupsGarbage?.forEach((user: { skillGroupId: string | number; displayOrder: any; }) => {
        skillGroupLevels[user.skillGroupId] = user.displayOrder;
    });

    utilizationGroupDataWithLevel.forEach((user: { skillGroupId: string | number; displayOrder: any; }) => {
        if (displayOrders[user.skillGroupId] !== undefined) {
            user.displayOrder = skillGroupLevels[user.skillGroupId];
        }
    });

    const utilizationGroupUsers = queueData?.skillGroupsGarbage?.map((companiesItem: { [x: string]: any; skillGroupId?: any; displayOrder?: any; }) => {
        const {displayOrder, ...userWithoutDisplayOrder} = companiesItem;
        const user = skillGroupList.find((userItem: { id: any; }) => userItem.id === companiesItem.skillGroupId);
        if (queueData?.queue?.id) {
            return {
                ...userWithoutDisplayOrder,
                skillGroupId: user?.id,
                id: user?.id,
                name: user?.name,
                queueId: queueData?.queue?.id
            };
        } else {
            return {
                ...userWithoutDisplayOrder,
                skillGroupId: user?.id,
                id: user?.id,
                name: user?.name
            };
        }
    });

    const filteredUtilizationSkillGroups = queueData?.skillGroupsGarbage
        ?.filter((skillGroup: { skillGroupId: any; }) =>
            utilizationGroupDataWithLevel?.some((levelGroup: { id: any; }) => levelGroup.id === skillGroup.skillGroupId)
        )
        .map((skillGroup: { skillGroupId: any; displayOrder: any; }) => {
            const user = utilizationGroupUsers.find((user: { skillGroupId: any; }) => user.skillGroupId === skillGroup.skillGroupId);
            return user ? {...user, displayOrder: skillGroup.displayOrder} : null;
        })
        .filter((user: null) => user !== null) || [];
    const utilizationGroupDataItemTemplate = (option: any) => {
        return skillGroupList.map((item: any) => {
            if (item.id === option.skillGroupId) {
                return item.name
            }
        })
    }

    const onRowReorder = (e: { dragIndex: number; dropIndex: number; }) => {
        let updatedRows = [...queueData.skillGroupsGarbage];
        const draggedRow = updatedRows[e.dragIndex];

        updatedRows.splice(e.dragIndex, 1);

        updatedRows.splice(e.dropIndex, 0, draggedRow);

        updatedRows = updatedRows.map((row, index) => ({
            ...row,
            displayOrder: index+1
        }));

        dispatch(changeQueueUtilizationGroupIndex(updatedRows));
    };

    const utilizationGroupItems = () => {
        const sortedUtilizationGroupUsers = (filteredUtilizationSkillGroups || [])
            .map((companiesItem: { skillGroupId: any; }) => {
                const user = skillGroupList.find((userItem: { id: any; }) => userItem.id === companiesItem.skillGroupId);
                return {
                    ...companiesItem,
                    skillGroupId: user?.id,
                    id: user?.id,
                    name: user?.name
                };
            })
            .sort((a: { displayOrder: number; }, b: { displayOrder: number; }) => a.displayOrder - b.displayOrder);

        const normalizeDisplayOrder = (array: any[]) => {
            const sortedArray = array?.sort((a, b) => a.displayOrder - b.displayOrder);

            return sortedArray?.map((item, index) => ({
                ...item,
                displayOrder: index + 1,
            }));
        };

        const filteredArray = normalizeDisplayOrder(sortedUtilizationGroupUsers);

        const buttonTemplate = (rowData: any) => {
            return <Button name={rowData.skillGroupId}
                           onClick={() => {
                               dispatch(deleteQueueUtilizationGroup(rowData.skillGroupId));
                           }}
                           icon="pi pi-times"
                           className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2"
                           disabled={!archiveTableToggle || !editType}
            />
        }

        const detailsBodyTemplate = (rowData: any) => {
            return hasRoleVisionPermission ? <Button
                className="max-w-4rem p-button-secondary"
                style={{minWidth: '40px'}}
                loading={skillGroupDetailsLoading && currentUserId === rowData.skillGroupId}
                disabled={!archiveTableToggle || skillGroupDetailsLoading}
                icon={archiveTableToggle && hasRoleUpdatePermission ? 'pi pi-pencil' : !archiveTableToggle || !hasRoleUpdatePermission ? 'pi pi-eye' : ''}
                onClick={() => {
                    setCurrentUserId(rowData.skillGroupId);
                    dispatch(getQualificationGroupDetails(rowData.skillGroupId, jwtToken?.jwtToken, true));
                    if (hasRoleUpdatePermission) {
                        dispatch(setEditType(true));
                    } else {
                        dispatch(setEditType(false));
                    }
                }}/> : ''
        }

        return <DataTable className="w-full" value={filteredArray} reorderableColumns reorderableRows onRowReorder={onRowReorder}>
            <Column rowReorder style={{width: '20px'}}/>
            <Column field="displayOrder" className="text-center" style={{minWidth: '10px', maxWidth: '40px'}}/>
            <Column field="id" body={detailsBodyTemplate} style={{minWidth: '10px', maxWidth: '40px'}}/>
            <Column field="name" style={{minWidth: '50%'}}/>
            <Column body={buttonTemplate} style={{width: '20px'}}/>
        </DataTable>
    }


    const isSkillGroupIdPresent = queueData?.skillGroups?.some((skillGroup: { skillGroupId: any; }) =>
        skillGroupDataWithLevel?.some((levelGroup: { id: any; }) => levelGroup.id === skillGroup.skillGroupId)
    );

    const addSkillGroup = (e: any) => {
        const filteredEValue = e.value?.filter((item: { skillGroupId: any; }) => item.skillGroupId);
        const existingIds = new Set(filteredEValue.map((item: { skillGroupId: any; }) => item.skillGroupId));
        const skillGroupData = new Set(skillGroupDataWithLevel.map((item: { skillGroupId: any; }) => item.skillGroupId));
        const newItems = queueData?.skillGroups?.filter((item: { skillGroupId: any; }) =>
            !existingIds.has(item.skillGroupId) && !skillGroupData.has(item.skillGroupId));
        const updatedEValue = [...filteredEValue, ...newItems];

        dispatch(addQueueSkillGroup({queueId: queueData?.id, skillGroupId: updatedEValue}));
    }

    const isUsersIdPresent = queueData?.queueUsers?.some((skillGroup: { userId: any; }) =>
        usersDataWithUserLevel?.some((levelGroup: { userId: any; }) => levelGroup.userId === skillGroup.userId)
    );

    const changeQueueUsers = (e: any) => {
        const filteredEValue = e.value?.filter((item: { userId: any; }) => item.userId);
        const existingIds = new Set(filteredEValue.map((item: { userId: any; }) => item.userId));
        const skillGroupData = new Set(usersDataWithUserLevel.map((item: { userId: any; }) => item.userId));
        const newItems = queueData?.queueUsers?.filter((item: { userId: any; }) =>
            !existingIds.has(item.userId) && !skillGroupData.has(item.userId));
        const updatedEValue = [...filteredEValue, ...newItems];

        dispatch(addQueueUser({queueId: queueData?.id, userId: updatedEValue}))
    }

    const addUtilizationGroup = (e: any) => {
        const filteredEValue = e.value?.filter((item: { skillGroupId: any; }) => item.skillGroupId);
        const existingIds = new Set(filteredEValue?.map((item: { skillGroupId: any; }) => item.skillGroupId));
        const utilizationGroupData = new Set(utilizationGroupDataWithLevel.map((item: { skillGroupId: any; }) => item.skillGroupId));
        const newItems = queueData?.skillGroupsGarbage?.filter((item: { skillGroupId: any; }) =>
            !existingIds.has(item.skillGroupId) && !utilizationGroupData.has(item.skillGroupId));

        const updatedEValue = [...filteredEValue, ...newItems];
        // @ts-ignore
        dispatch(addQueueUtilizationGroup({queueId: queueData?.id, skillGroupId: updatedEValue}))
    }

    return (
        <div className="users">
            <div className="grid p-fluid">
                <div className="field col-12 md:col-12 mb-0">
                    <h5 className="mr-2 mb-0">Опрацювання черги</h5>
                </div>
                <div className="field col-12 md:col-5">
                    <div className="field-radiobutton" style={{paddingBottom: '2px'}}>
                        <h6 className="mr-2 mb-0">Оператори</h6>
                        <InputSwitch disabled={!archiveTableToggle || !editType} checked={queueData?.queue?.isSkillGroup}  onChange={(e) => {
                            dispatch(setQueueField({section: 'queue', fieldName: 'isSkillGroup', value: e.value}));
                        }}/>
                        <h6 className="my-0 ml-2">Група кваліфікацій</h6>
                    </div>

                    {!queueData?.queue?.isSkillGroup && <div><MultiSelect
                        appendTo="self"
                        value={campaignUsers}
                        options={usersDataWithUserLevel}
                        onChange={(e) => {
                            changeQueueUsers(e)
                        }}
                        itemTemplate={usersDataItemTemplate}
                        placeholder="Оберіть користувачів"
                        filter
                        optionLabel="fullName"
                        selectedItemsLabel={isUsersIdPresent ? `Користувачив обрано: ${filteredUsers?.length}` : ""}
                        maxSelectedLabels={0}
                        scrollHeight="250px"
                        className={classNames('field col-12 md:col-12 p-0', {'p-invalid': errorFields?.queueUsers && !queueData?.queueUsers?.length})}
                        disabled={!archiveTableToggle || !editType}
                    />
                        {
                            queueData?.queueUsers?.length && isUsersIdPresent ?
                                <div className="users-list">
                                    {userItems}
                                </div>
                                :
                                <span className="no-users-message">Користувачив не обрано</span>
                        }
                    </div>}
                    {queueData?.queue?.isSkillGroup && <div>
                        <MultiSelect
                            appendTo="self"
                            value={skillGroupUsers}
                            options={skillGroupDataWithLevel}
                            onChange={(e) => {
                                addSkillGroup(e);
                            }}
                            className={classNames('field col-12 md:col-12 p-0', {'p-invalid': errorFields?.queueUsers && !queueData?.queueUsers?.length})}
                            itemTemplate={skillGroupDataItemTemplate}
                            placeholder="Оберіть групу"
                            filter
                            optionLabel="name"
                            selectedItemsLabel={isSkillGroupIdPresent ? `Груп обрано: ${filteredSkillGroups?.length}` : ""}
                            maxSelectedLabels={0}
                            scrollHeight="250px"
                            disabled={!archiveTableToggle || !editType}
                        />
                        {
                            queueData?.skillGroups?.length && isSkillGroupIdPresent  ?
                                <div className="users-list">
                                    {skillGroupItems}
                                    <div className="grid col-12 md:col-12">
                                        {errorFields?.campaignUsers && <div className="col-12 md:col-12 p-0 m-0 pl-2 mb-2 w-full"><span className="title p-error text-sm">{errorFields?.campaignUsers?.campaignUsers}</span></div>}
                                    </div>
                                </div>
                                :
                                <span className="no-users-message">Груп не обрано</span>
                        }
                    </div>}
                </div>
                <div className="field col-12 md:col-3">
                    <h6 className="pb-1">Стратегія оператор:</h6>
                    <Dropdown id="type"
                              appendTo="self"
                              value={queueData.queue.strategyOperators}
                              options={queuesDirectoryQueueOperatorsType.map((item: { dictionaryId: number; }) => item.dictionaryId)}
                              onChange={e => {
                                  dispatch(setQueueField({section: 'queue', fieldName: 'strategyOperators', value: e.target.value}))
                              }}
                              itemTemplate={campaignStrategyItemTemplate}
                              valueTemplate={selectedCampaignStrategyTemplate}
                              disabled={!archiveTableToggle || !editType}
                              placeholder="Оберіть тип"
                    />
                </div>
                <div className="grid col-12 md:col-12">
                    {errorFields?.queueUsers && <div className="col-12 md:col-12 p-0 m-0 pl-2 mb-2 w-full"><span className="title p-error text-sm">{errorFields?.queueUsers?.queueUsers}</span></div>}
                </div>
            </div>
            {!PROD && <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>}
            <div className="grid p-fluid">
                <div className="field col-12 md:col-12 mb-0">
                    <h5 className="mr-2 mb-0">Стратегія утилізації</h5>
                </div>
                <div className="grid field col-12 md:col-12">
                    <div className="field col-12 md:col-4">
                        <h6>Група кваліфікацій</h6>
                        <MultiSelect
                            appendTo="self"
                            value={utilizationGroupUsers}
                            options={utilizationGroupDataWithLevel}
                            onChange={(e) => {
                                addUtilizationGroup(e);
                            }}
                            itemTemplate={utilizationGroupDataItemTemplate}
                            placeholder="Оберіть групу"
                            filter
                            optionLabel="name"
                            selectedItemsLabel={filteredUtilizationSkillGroups?.length ? `Груп обрано: ${filteredUtilizationSkillGroups?.length}` : ""}
                            maxSelectedLabels={0}
                            scrollHeight="250px"
                            disabled={!archiveTableToggle || !editType}
                        />
                        {
                            queueData?.skillGroupsGarbage?.length && filteredUtilizationSkillGroups?.length ?
                                <div className="users-list">
                                    {utilizationGroupItems()}
                                    <div className="grid col-12 md:col-12">
                                        {errorFields?.campaignUsers && <div className="col-12 md:col-12 p-0 m-0 pl-2 mb-2 w-full"><span className="title p-error text-sm">{errorFields?.campaignUsers?.campaignUsers}</span></div>}
                                    </div>
                                </div>
                                :
                                <span className="no-users-message">Груп не обрано</span>
                        }
                    </div>
                    <div className="field col-12 md:col-3">
                        <h6>Стратегія оператор:</h6>
                        <Dropdown id="type"
                                  appendTo="self"
                                  value={queueData.queue.strategyOperatorsForGarbage}
                                  options={queuesDirectoryQueueOperatorsType.map((item: { dictionaryId: number; }) => item.dictionaryId)}
                                  onChange={e => {
                                      dispatch(setQueueField({section: 'queue', fieldName: 'strategyOperatorsForGarbage', value: e.target.value}))
                                  }}
                                  itemTemplate={campaignStrategyItemTemplate}
                                  valueTemplate={selectedCampaignStrategyTemplate}
                                  disabled={!archiveTableToggle || !editType}
                                  placeholder="Оберіть тип"
                        />
                    </div>
                </div>
                {/*{!PROD && <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>}*/}
                {/*{!PROD && <div>*/}
                {/*    <label htmlFor="name">Видача активности</label>*/}
                {/*    <div className="field-radiobutton my-4 pl-2">*/}
                {/*        <label htmlFor="name" className="mr-2">Ручна</label>*/}
                {/*        <InputSwitch*/}
                {/*            disabled*/}
                {/*            checked={queueData?.queueData?.firstCallOperator} onChange={(e) => {*/}
                {/*            dispatch(setQueueField({section: 'queueData', fieldName: 'firstCallOperator', value: e.value}));*/}
                {/*        }}/>*/}
                {/*        <label htmlFor="icon">Автоматична</label>*/}
                {/*    </div>*/}
                {/*</div>}*/}
            </div>
            <UserListDialog/>
            <QualificationGroupDialog/>
        </div>
    )
}

export default QueueListDialogUsers
