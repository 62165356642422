import {PROD} from "../redux/config";

export const statusesDict = [
    {name: 'UNSUCCESS', code: 'UNSUCCESS'},
    {name: 'SUCCESS', code: 'SUCCESS'},
    {name: 'ACTIVE', code: 'ACTIVE'},
    {name: 'ACTION', code: 'ACTION'},
    {name: 'PROCESSING', code: 'PROCESSING'}
];

export const amdHangupDict = [
    {name: 'Автовідповідач', code: '1'},
    {name: 'NULL', code: '0'},
];

export const typeChannelDict = [
    {name: 'Вхідний', code: 'internal'},
    {name: 'Вихідний', code: 'external'},
    {name: 'Вихідний Сallback', code: 'callback'},
    {name: 'Трансфер Внутрішній', code: 'transferInternal'},
    {name: 'Трансфер Зовнішній', code: 'transferExternal'},
    {name: 'Пропущений', code: 'internalSkipped'},
    {name: 'Вхідний Консультація', code: 'consultationInternal'},
    {name: 'Вихідний Консультація', code: 'consultationExternal'}
];

export const languages = [
    {name: 'en', id: 'en'},
    {name: 'uk', id: 'uk'},
];

export const voices = [
    {name: 'Жіночий', id: "female"},
    {name: 'Чоловічий', id: "male"},
];

export let isAudio = [
    {name: 'Аудіофайл', state: true},
    {name: 'Диктор', state: false},
];

if (PROD) {
    isAudio = [
        {name: 'Аудіофайл', state: true},
    ];
}
