import axios from "axios";
import {API_URL} from "../config";
import {GET_ERROR_USERS_WEB_RTC, GET_USERS_WEB_RTC} from "../types/types";
import {showAccessDeniedNotifications} from "../actions/actionsNotifications";
import {getConsultationMuteId, getMuteId} from "../actions/actionsWebRTC";

export function getUsersWebRTC(searchValue: any, jwtToken: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.get(`${API_URL}UserResource/SearchUsersAndQueues?search=${searchValue}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            }
        })
            .then((response) => {

                let arraySearch: any = [];

                    let users = response.data?.users;
                    let queues = response.data?.queues;

                    if(users) arraySearch.push(...users);
                    if(queues) arraySearch.push(...queues);
                dispatch({type: GET_USERS_WEB_RTC, payload: arraySearch})

            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    return dispatch({type: GET_ERROR_USERS_WEB_RTC, payload: true});
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}

export function sendCallEvents(type: string, data: any, jwtToken: any, isConsultation?: any) {
    return (dispatch: (arg0: any) => void) => {
        axios.post(`${API_URL}VoipUsers/ReadEvent?eventType=${type}`, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${jwtToken}`
            },
        })
            .then((response) => {
                if (isConsultation) {
                    dispatch(getConsultationMuteId(response.data));
                } else {
                    dispatch(getMuteId(response.data));
                }
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    dispatch(showAccessDeniedNotifications(true));
                } else {
                    dispatch(getMuteId(0));
                }
            })
            .finally(() => {
                dispatch(showAccessDeniedNotifications(false));
            })
    }
}
