import {
    CLEAR_FILTERS,
    SHOW_LOADER,
    HIDE_LOADER,
    SHOW_ERROR,
    HIDE_ERROR,
    HIDE_ERROR_MESSAGE,
    SHOW_UPDATED_ERROR,
    USERS_TABLE_TOGGLE,
    SELECTED_USER_ID,
    USER_FIELD,
    USERS_DIALOG_VISIBLE,
    SHOW_USER_CREATE_MESSAGE,
    SHOW_USER_SAVE_MESSAGE,
    SHOW_USER_DELETE_MESSAGE,
    HIDE_USER_CREATE_MESSAGE,
    HIDE_USER_SAVE_MESSAGE,
    USER_STATE_SAVE,
    INITIAL_USER_DATA,
    CHANGED_USER_DATA,
    GET_USERS_LIST,
    GET_USER_DETAILS,
    GET_USER_ROLES,
    GET_USER_BRANCHES,
    GET_USER_COUNTRIES,
    GET_USER_LANGUAGES,
    SHOW_USER_ERROR_FIELD,
    SET_USER_ERROR_FIELDS,
    SHOW_USER_ERROR,
    HIDE_USER_DELETE_MESSAGE,
    GET_USER_PAGINATION,
    SET_USER_CURRENT_PAGE,
    SET_USER_CURRENT_SIZE,
    SET_USER_DELETE_MESSAGE_SUCCESS,
    SHOW_USER_DETAIL_LOADING,
    GET_USER_STATUSES,
    SET_USER_STATUS_SUCCESS,
    SET_USER_STATUS_ERROR, SET_USER_STATUS, SET_USER_LIST_DATA_SORT, USERS_DIALOG_UNZIPPING, SET_CHECK_USER_FOR_CALL, GET_USER_ALL_ROLES, GET_USERS_DIRECTORIES_TRUNKS_PHONE, SET_USER_TRUNK_IDS, GET_PHONES_DICTIONARY, GET_USER_STATUSES_BY_ID
} from "../types/typesUsersList";

import {FilterMatchMode, FilterOperator} from "primereact/api";
import {CHANGE_PASSWORD_LOADING, CHANGE_PASSWORD_SUCCESS, GET_HASH_LOADING, SET_ACTIVE_CLICK} from "../types/types";
import {GET_TECH_USER_DICTIONARY, GET_TRUNKS_DICTIONARY, SUCCESS_CREATED_TECH_USER} from "../types/typesRoles";

const initialState = {
    usersActiveTableToggle: true,
    dialogVisible: false,
    selectedUserId: null,
    clearFilters: {
        id: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        fullName: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        ownerName: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        branchName: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        role: {value: null, matchMode: FilterMatchMode.IN},
        status: {value: null, matchMode: FilterMatchMode.IN},
        trunkNumbers: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    },
    usersList: [],
    pagination: {
        countPage: 0,
        totalItems: 0,
    },
    dataSort: {
        isActive: true,
        pageNumber: 1,
        pageSize: 10,
        sort: {
            orderBy: "Id",
            isDesc: true
        }
    },
    paginationPage: 1,
    paginationSize: 10,
    userData: {
        user: {
            userName: null,
            lastName: null,
            firstName: null,
            middleName: null,
            sipName: null,
            phoneNo: null,
            email: null,
            password: null,
            billingId: 1,
            branchId: null,
            actionByUserId: null,
            languageId: 0,
            birthDate: null,
            recruitedDate: null,
            firedDate: null,
            userDateTimeUTC: "2023-07-10T08:52:10.538Z",
            utc: null,
            countryId: 0,
            theme: "dark",
            description: null,
            roles: null,
            callTypeReceive: 401
        },
        userData: {
            createdByUserId: null,
            updatedByUserId: null,
            createdDateTime: null,
            updatedDateTime: null,
        },
    },
    usersData: [],
    roles: [],
    allRoles: [],
    branches: [],
    languages: [],
    countries: [],
    statuses: [],
    statusesById: [],
    phonesDictionary: [],
    userTrunksDictionary: [],
    userStatus: [],
    loading: false,
    error: false,
    errorUpdate: false,
    errorMessage: null,
    userCreateMessage: false,
    userSaveMessage: false,
    userDeleteMessage: false,
    userStateSave: false,
    errorField: null,
    errorFields: null,
    getNotificationError: false,
    userDeleteSuccess: false,
    detailLoading: false,
    setUserStatus: null as string | null,
    setUserStatusError: null as string | null,
    activeClick: null,
    usersDialogUnzipping: true,
    checkUserForCalling: false,
    usersDirectoryTrunksPhone: [],
    techUserDictionary: [],
    successCreated: false
}

export const UsersListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case USERS_TABLE_TOGGLE:
            return {...state, usersActiveTableToggle: action.payload}
        case USERS_DIALOG_VISIBLE:
            return {...state, dialogVisible: action.payload}
        case SELECTED_USER_ID:
            return {...state, selectedUserId: action.payload}
        case USER_FIELD:
            return {
                ...state, userData:
                    {
                        ...state.userData,
                        [action.payload.section]: {
                            // @ts-ignore
                            ...state.userData[action.payload.section],
                            [action.payload.fieldName]: action.payload.value,
                        }
                    }
            }
        case CLEAR_FILTERS:
            return {
                ...state, clearFilters: {
                    id: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    userName: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    ownerName: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    branchName: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    trunkNumbers: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
                    role: {value: null, matchMode: FilterMatchMode.IN},
                }
            }
        case GET_USERS_LIST:
            return {...state, usersList: action.payload}
        case GET_USER_PAGINATION:
            return {...state, pagination: {countPage: action.payload.countPage, totalItems: action.payload.totalItems}}
        case SET_USER_CURRENT_PAGE:
            return {...state, paginationPage: action.payload}
        case SET_USER_CURRENT_SIZE:
            return {...state, paginationSize: action.payload}
        case GET_USER_DETAILS:
            return {...state, userData: {user: action.payload}}
        case GET_USER_ROLES:
            return {...state, roles: action.payload}
        case GET_USER_ALL_ROLES:
            return {...state, allRoles: action.payload}
        case GET_USER_BRANCHES:
            return {...state, branches: action.payload}
        case GET_USER_LANGUAGES:
            return {...state, languages: action.payload}
        case GET_USER_COUNTRIES:
            return {...state, countries: action.payload}
        case GET_USER_STATUSES:
            return {...state, statuses: action.payload}
        case GET_USER_STATUSES_BY_ID:
            return {...state, statusesById: action.payload}
        case SHOW_LOADER:
            return {...state, loading: true}
        case HIDE_LOADER:
            return {...state, loading: false}
        case SHOW_ERROR:
            return {...state, error: true}
        case SHOW_UPDATED_ERROR:
            return {...state, errorUpdate: true}
        case SHOW_USER_CREATE_MESSAGE:
            return {...state, userCreateMessage: true}
        case SHOW_USER_SAVE_MESSAGE:
            return {...state, userSaveMessage: true}
        case SHOW_USER_DELETE_MESSAGE:
            return {...state, userDeleteMessage: action.payload}
        case HIDE_USER_CREATE_MESSAGE:
            return {...state, userCreateMessage: false}
        case HIDE_USER_DELETE_MESSAGE:
            return {...state, userDeleteMessage: false}
        case HIDE_USER_SAVE_MESSAGE:
            return {...state, userSaveMessage: false}
        case HIDE_ERROR:
            return {...state, error: false, errorUpdate: false}
        case USER_STATE_SAVE:
            return {...state, userStateSave: action.payload}
        case INITIAL_USER_DATA:
            return {...state, userData: initialState.userData}
        case SHOW_USER_ERROR:
            return {...state, errorMessage: action.payload}
        case SHOW_USER_ERROR_FIELD:
            return {...state, errorField: action.payload}
        case SET_USER_ERROR_FIELDS:
            return {...state, errorFields: action.payload}
        case HIDE_ERROR_MESSAGE:
            return {...state, errorMessage: null}
        case CHANGED_USER_DATA:
            return {...state, changedUserData: action.payload}
        case SET_USER_DELETE_MESSAGE_SUCCESS:
            return {...state, userDeleteSuccess: action.payload}
        case SHOW_USER_DETAIL_LOADING:
            return {...state, detailLoading: action.payload}
        case SET_USER_STATUS_SUCCESS:
            return {...state, setUserStatus: action.payload}
        case SET_CHECK_USER_FOR_CALL:
            return {...state, checkUserForCalling: action.payload}
        case SET_USER_STATUS_ERROR:
            return {...state, setUserStatusError: action.payload}
        case SET_USER_STATUS:
            return {...state, userStatus: action.payload}
        case SET_ACTIVE_CLICK:
            return {...state, activeClick: action.payload}
        case USERS_DIALOG_UNZIPPING:
            return {...state, usersDialogUnzipping: action.payload}
        case SET_USER_LIST_DATA_SORT:
            return {...state, dataSort: action.payload}
        case GET_USERS_DIRECTORIES_TRUNKS_PHONE:
            return {...state, usersDirectoryTrunksPhone: action.payload};
        case GET_PHONES_DICTIONARY:
            return {...state, phonesDictionary: action.payload};
        case SET_USER_TRUNK_IDS:
            return {...state, usersTrunkIds: action.payload};
        case GET_TRUNKS_DICTIONARY:
            return {...state, userTrunksDictionary: action.payload};
        case GET_TECH_USER_DICTIONARY:
            return {...state, techUserDictionary: action.payload};
        case SUCCESS_CREATED_TECH_USER:
            return {...state, successCreated: action.payload};
        case GET_HASH_LOADING:
            return {...state, hashLoading: action.payload};
        case CHANGE_PASSWORD_LOADING:
            return {...state, changePasswordLoading: action.payload};
        case CHANGE_PASSWORD_SUCCESS:
            return {...state, changePasswordSuccess: action.payload};
        default:
            return state
    }
}
